import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Report } from 'src/model/report';

@Injectable({
  providedIn: 'root'
})

export class ReportService {

  constructor(private http: HttpClient) { }
 
  private url:string = environment.url;

  getPerSession(sessionId: number): Observable<Report> {
    return this.http.get<Report>(this.url + '/api/v2/session_report/perSession?sessionId=' + sessionId, this.getOption());
  }

  getPerModerator(userId: number, time1: string, time2: string): Observable<Report> {
    const inputDate1 = this.formatDate(new Date(time1));
    const inputDate2 = this.formatDate(new Date(time2));
    return this.http.get<Report>(this.url + '/api/v2/session_report/perModerator?userId=' + userId + 
      '&time1=' + inputDate1 + '&time2=' + inputDate2, this.getOption())
  }

  getPerForum(forumId: number, time1: string, time2: string): Observable<Report> {
    const inputDate1 = this.formatDate(new Date(time1));
    const inputDate2 = this.formatDate(new Date(time2));
    return this.http.get<Report>(this.url + '/api/v2/session_report/perForum?forumId=' + forumId + 
      '&time1=' + inputDate1 + '&time2=' + inputDate2, this.getOption())
  }

  getToken(): string {
    return localStorage.getItem('token')!;
  }

  getOption() {
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.getToken()});
    return { headers: httpHeaders };
  }

  formatDate(date: Date) {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
}

