import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, Observable, startWith } from 'rxjs';
import { Classification } from 'src/model/classification';
import { ClassificationService } from 'src/services/classification.service';
import { TopicService } from 'src/services/topic.service';

@Component({
  selector: 'app-topic-dialog',
  templateUrl: './topic-dialog.component.html',
  styleUrls: ['./topic-dialog.component.scss']
})
export class TopicDialogComponent implements OnInit {

  form!: FormGroup;
  formState: string = '';

  classificationControl = new FormControl<string | Classification>('');
  classifications: Classification[] = [];
  filteredClassifications!: Observable<Classification[]>;
  selectedClassification: number = 0;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private topicService: TopicService,
    private classificationService: ClassificationService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<TopicDialogComponent>,
    private spinner: NgxSpinnerService) {
      this.formState = data.formState;
      
      
      if(this.formState == 'NEW'){
        this.createForm(data.data);
      }else{
        this.createUpdateForm(data.data);
      }

  }

  ngOnInit() {
    this.getAllCLassifications();
  }

  createForm(form: any) {
    
    return this.form = this.formBuilder.group({
      id:  [form?.id || 0, [Validators.required]],
      name: [form?.name || '', [Validators.required]],
      classificationId:  [form?.classificationId || '', [Validators.required]],
      parentId: [form?.parentId || '', [Validators.required]],
      level:  [form?.level || '', [Validators.required]],
    });
  }

  createUpdateForm(form: any) { 
    return this.form = this.formBuilder.group({
      id:  [form?.id || 0, [Validators.required]],
      name: [form?.name || '', [Validators.required]],
    });
  }



  displayFn(classification: Classification): string {
    this.selectedClassification = classification.id;
    return classification != null ? classification.name : '';
  }

  private _filter(name: string): Classification[] {
    const filterValue = name.toLowerCase();
    return this.classifications.filter(classification => classification.name.toLowerCase().includes(filterValue));
  }

  save(){
    if(this.formState == 'NEW'){
      this.form.get("classificationId")!.patchValue(this.selectedClassification);
      this.addTopic();       
    } else {
      this.updateTopic();
    }
  }

  addTopic() {
    this.spinner.show();
    this.topicService.saveTopic(this.form.getRawValue())
      .subscribe({
        next: (result: any) => { },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); this.openSnackBar("Successfully added.", 'Close'); this.dialogRef.close({data: this.form.get("classificationId")!.getRawValue()}); }

      })
  }

  updateTopic() {
    this.spinner.show();
    this.topicService.updateTopic(this.form.getRawValue())
      .subscribe({
        next: (result: any) => { },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); this.openSnackBar("Successfully updated.", 'Close'); this.dialogRef.close(true); }

      })
  }

  getAllCLassifications(){
    this.spinner.show();
    this.classificationService.getClassificationList() 
    .subscribe({
      next: (result: any) => {
        this.classifications = result;
        this.filteredClassifications = this.classificationControl.valueChanges.pipe(
          startWith(''),
         map(value => {
          const name = typeof value === 'string' ? value : value?.name;
          return name ? this._filter(name as string) : this.classifications.slice();
        }),
        );
       
      },
      error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
      complete: () => { this.spinner.hide(); }

    })
  }
  onCancel(): void {
    this.dialogRef.close(false);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 3000 });
  }
}