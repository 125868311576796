import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, Observable, startWith } from 'rxjs';
import { Classification } from 'src/model/classification';
import { Moderator } from 'src/model/moderator';
import { SessionList } from 'src/model/sessionNew';
import { Topic } from 'src/model/topic';
import { ClassificationService } from 'src/services/classification.service';
import { ForumService } from 'src/services/forum.service';
import { TopicService } from 'src/services/topic.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

interface TopicList {
  id: number;
  name: string;
  path: string;
}


interface ExampleFlatNode {
  expandable: boolean;
  id: number;
  name: string;
  level: number;
}

@Component({
  selector: 'app-forum-topic-dialog',
  templateUrl: './forum-topic-dialog.component.html',
  styleUrls: ['./forum-topic-dialog.component.scss']
})
export class ForumTopicDialogComponent implements OnInit {

  form!: FormGroup;
  formState: string = '';
  moderatorsList: Moderator[] = [];
  topicsList: any[] = [];
  selectedModeratorsList: Moderator[] = [];
  selectedTopicsList: Topic[] = [];
  defaultClassificationId: number = 0;

  topicControl = new FormControl<string | TopicList>('');
  topics: TopicList[] = [];
  filteredTopics!: Observable<TopicList[]>;
  sessionsList: SessionList[] = [];

  classificationControl = new FormControl<string | Classification>('');
  classifications: Classification[] = [];
  filteredClassifications!: Observable<Classification[]>;

  dataSourceClass: [] = [];


  classificationSelect: Classification = {} as Classification;

  topicSelect: TopicList = {
    id: 1,
    name: 'Public',
    path: ''
  };

  topicList: TopicList[] = [];
  topicListFromTree: Topic[] = [];
  selectedTopics: number[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private forumService: ForumService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ForumTopicDialogComponent>,
    private topicService: TopicService,
    private spinner: NgxSpinnerService,
    private classificationService: ClassificationService) {
      this.formState = data.formState;

      this.createForm(data.data);
     
      if(data.data.id != null || data.data.id != undefined){
        // this.getForumModeratorsById(data.data.id);
        this.getForumTopics(data.data.id);
      }
       
      
  }

  ngOnInit() {
    this.getForumModerators();
    this.getAllClassifications();
  }

  createForm(form: any) {   
    return this.form = this.formBuilder.group({
      forumId:  [form?.id || 0, [Validators.required]],
      topicIds: [form?.topics || [], [Validators.required]]
    });
  }

  save(){
    this.form.get("topicIds")!.patchValue(this.selectedTopics);
    this.updateForum();
  }

  updateForum() {
    // this.forumService.updateForumTopics(this.form.getRawValue())
    //   .subscribe({
    //     next: (result: any) => { },
    //     error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
    //     complete: () => { this.spinner.hide(); this.openSnackBar("Successfully updated.", 'Close'); this.dialogRef.close(true); }

    //   })
  }

  getForumModerators(){
    this.spinner.show();
    this.forumService.getForumModerators()
    .subscribe({
      next: (result: any) => {
      this.moderatorsList = result;
      },
      error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
      complete: () => { this.spinner.hide();  }

    })
  }

  getForumModeratorsById(id: number){
    this.spinner.show();
    this.forumService.getForumModeratorsById(id)
    .subscribe({
      next: (result: any) => {
      this.selectedModeratorsList = result;
      let moderators = this.selectedModeratorsList.map(a => a.id);
      
      this.form.get("moderators")!.patchValue(moderators);
      
      },
      error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
      complete: () => { this.spinner.hide();  }

    })
  }

  getNameOfFirstModerator(){    
    let moderatorId = this.form.get("moderators")!.getRawValue()[0];
    if(moderatorId){
      let mod = this.moderatorsList.find(x => x.id == moderatorId);      
      return mod?.firstName + ' ' + mod?.lastName;
    }
    return null;
  }

  getNameOfFirstTopic(){   
    
    for (let i = 0; i < this.form.get("topicIds")!.getRawValue().length; i++) {
      if(this.form.get("topicIds")!.getRawValue()[i]){
        let top = this.topicList.find(x => x.id == this.form.get("topicIds")!.getRawValue()[i]);      
        if (top) {
          return top?.name;
        }
        
      }
      
    }
    return null;
    
  }



  onCancel(): void {
    this.dialogRef.close(false);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 3000 });
  }

  displayFnTopic(topic: TopicList): string {
    return topic != null ? topic.name : '';
  }


  private _filterTopic(name: string): TopicList[] {
    let filterValue = name.toLowerCase();

    return this.topicList.filter(topic => topic.name.toLowerCase().includes(filterValue));
  }

  getAllClassifications() {
    this.spinner.show();
    this.classificationService.getClassificationList()
      .subscribe({
        next: (result: any) => {
          this.defaultClassificationId = result?.find((x: Classification) => x.name == 'Default').id; //activeClassifications;
          //let activeClassifications = result.filter((x: Classification) => x.deleted == false);          
          this.dataSourceClass = result; //activeClassifications;
          this.classifications = result; //activeClassifications;
          //console.log(this.classifications);
          
          this.filteredClassifications = this.classificationControl.valueChanges.pipe(
            startWith(''),
            map(value => {
              const name = typeof value === 'string' ? value : value?.name;
              return name ? this._filter(name as string) : this.classifications.slice();
            }),
          );
        },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); }

      })
  }

  onClassificationChange(selectedClassification: Classification){
    this.getAllTopicsForForum(selectedClassification.id);
  }


  onOptionClick(event: any){
    this.selectedTopics = this.selectedTopics.concat([event.id]);
    this.selectedTopics = this.removeDuplicates(this.selectedTopics);
  }

  removeDuplicates(arr: number[]): number[] {
    const frequencyMap: { [key: number]: number } = {};
  
    // Count the frequency of each element
    for (const num of arr) {
      frequencyMap[num] = (frequencyMap[num] || 0) + 1;
    }
  
    // Filter out elements that occur more than once
    const result: number[] = arr.filter(num => frequencyMap[num] === 1);
  
    return result;
  }

  // getAllTopics() {    
  //   this.topicService.getFullTree(this.classificationSelect.id)
  //     .subscribe({
  //       next: (result: any) => {
  //         this.dataSource.data = result;
          
  //       },
  //       error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
  //       complete: () => { this.spinner.hide(); }

  //     })
  // }

  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.subtopics && node.subtopics.length > 0,
      id: node.id,
      name: node.name,
      level: level
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.subtopics
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  displayFn(classification: Classification): string {
    return classification != null ? classification.name : '';
  }

  private _filter(name: string): Classification[] {
    let filterValue = name.toLowerCase();

    return this.classifications.filter(classification => classification.name.toLowerCase().includes(filterValue));
  }

  getAllTopicsForForum(id: number) {
    this.spinner.show();
    this.topicService.getFullTree(id)
      .subscribe({
        next: (result: any) => {

          this.topicListFromTree = this.convertTreeToList(result);
          this.topicList = this.createPath(this.topicListFromTree);
          this.form.get("topicIds")!.patchValue(this.selectedTopics);

        },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); }

      })
  }

  getForumTopics(forumId: number){
    this.spinner.show();
    this.topicService.getForumTopics(forumId)
    .subscribe({
      next: (result: any) => {
      this.selectedTopicsList = result;
      
      let topics = this.selectedTopicsList.map(a => a.id);
      
      this.selectedTopics = topics;
      this.form.get("topicIds")!.patchValue(topics);
      },
      error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
      complete: () => { this.spinner.hide();  }

    })
  }

  convertTreeToList(root: any) {
    let stack = [];
    let array: any[] = [];
    let hashMap = {};
    stack = root;

    while (stack.length !== 0) {
      var node = stack.pop();

      if (node.subtopics.length == 0) {
        array.push(node)
      } else {
        array.push(node)
        for (var i = node.subtopics.length - 1; i >= 0; i--) {
          stack.push(node.subtopics[i]);
        }
      }
    }
    return array;
  }

  createPath(topicList: Topic[]) {
    let array: TopicList[] = []

    topicList.forEach(element => {
      let level = element.level;
      let parentId = element.parentId;
      let name = element.name;
      let id = element.id;
      let path = element.name;

      for (let i = level; i > 0; i--) {
        let parentTopic = topicList.find(x => x.id == parentId);
        if (parentTopic) {
          parentId = parentTopic.parentId;
          path = parentTopic.name + '/' + path;
        }
      }

      if (path == name)
        path = '';

      let newTopic: TopicList = {
        id: id,
        name: name,
        path: path
      }

      array.push(newTopic);
    });

    return array;

  }
}
