import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Classification } from 'src/model/classification';

@Injectable({
  providedIn: 'root'
})
export class ClassificationService {

  constructor(private http: HttpClient) { }

  private url:string = environment.url;
  private API_CLASSIFICATION:string = `${this.url}/api/v2/classification`;

  getToken(): string {
    return localStorage.getItem('token')!;
  }

  getOption() {
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.getToken()});
    return { headers: httpHeaders };
  }

  getClassificationList(): Observable<Classification[]> {
    return this.http.get<Classification[]>(this.API_CLASSIFICATION, this.getOption());
  }

  saveClassification(name: string): Observable<Classification> {
    return this.http.post<Classification>(this.API_CLASSIFICATION,
      {
        'name': name
      }, 
      this.getOption());
  }

  updateClassification(id: number, name: string): Observable<any> {
    return this.http.patch<any>(this.API_CLASSIFICATION,
      {
        'id': id,
        'name': name
      },
      this.getOption());
  }


  deleteClassification(id: number): Observable<any> {
    return this.http.delete<any>(this.API_CLASSIFICATION+'/'+id, this.getOption())
  }

}
