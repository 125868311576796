import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Organization } from 'src/model/organization';
import { OrganizationService } from 'src/services/organization.service';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {

  form!: FormGroup;
  formState: string = '';
  organizationsList: Organization[] = [];

  pattern = /^[a-zA-Z\s]+$/;
  // moderator: boolean = true;
  // admin: boolean = true;
  // publicUser: boolean = true;
  // guestSpeaker: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private organizationService: OrganizationService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UserDialogComponent>,
    private spinner: NgxSpinnerService) {
      this.formState = data.formState;
      this.createForm(data.data);
      console.log(data);
      
  }

  ngOnInit() {
    this.getOrganizations();
    if(this.formState == 'EDIT'){

      this.form.controls['email'].disable(); 
    }
  }

  createForm(form: any) {

    return this.form = this.formBuilder.group({
      id: [form?.id || 0],
      firstName: [form?.firstName || '', [Validators.required]],
      lastName: [form?.lastName || '', [Validators.required]],
      email: [form?.email || '', [Validators.required]],
      organizationId:  [form?.organization || null, [Validators.required]],
      moderator: [form?.moderator || false],
      admin: [form?.admin || false],
      publicUser: [form?.publicUser || false],
      guestSpeaker: [form?.guestSpeaker || false],
    });
  }

  save(){
    this.emailToLowercase();
    if(this.formState == 'NEW'){
      this.addUser();    
    } else {
      this.updateUser();
    }
  }

  addUser() {
    if(this.form.get('email')?.value == ''){
      this.openSnackBar("Email is mandatory field.", 'Close');
      return;
    }

    if(!this.pattern.test(this.form.get('firstName')?.value)){
      this.openSnackBar("Firstname is mandatory field and can't contain special characters.", 'Close');
      return;
    }

    if(!this.pattern.test(this.form.get('lastName')?.value)){
      this.openSnackBar("Lastname is mandatory field and can't contain special characters.", 'Close');
      return;
    }

    if(!(this.form.get('moderator')?.value || this.form.get('admin')?.value 
              || this.form.get('publicUser')?.value || this.form.get('guestSpeaker')?.value)){
      this.openSnackBar("Select atleast one user type.", 'Close');
      return;
    }

    this.spinner.show();
    this.userService.saveUser(this.form.getRawValue())
      .subscribe({
        next: (result: any) => { },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); this.openSnackBar("Successfully added.", 'Close'); this.dialogRef.close(true); }

      })
  }

  updateUser() {
    if(this.form.get('email')?.value == ''){
      this.openSnackBar("Email is mandatory field.", 'Close');
      return;
    }

    if(!this.pattern.test(this.form.get('firstName')?.value)){
      this.openSnackBar("Firstname is mandatory field and can't contain special characters.", 'Close');
      return;
    }

    if(!this.pattern.test(this.form.get('lastName')?.value)){
      this.openSnackBar("Lastname is mandatory field and can't contain special characters.", 'Close');
      return;
    }

    if(!(this.form.get('moderator')?.value || this.form.get('admin')?.value 
              || this.form.get('publicUser')?.value || this.form.get('guestSpeaker')?.value)){ 
      this.openSnackBar("Select atleast one user type.", 'Close');
      return;
    }
    
    this.spinner.show();
    this.userService.updateUser(this.form.getRawValue())
      .subscribe({
        next: (result: any) => { 
          
        },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); this.openSnackBar("Successfully updated.", 'Close'); this.dialogRef.close(true); }
      })
  }

  getOrganizations(){
    this.spinner.show();
    this.organizationService.getOrganizationList()
    .subscribe({
      next: (result: any) => {
      this.organizationsList = result;      
      },
      error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
      complete: () => { 
        this.spinner.hide();
        if(this.formState == 'EDIT')
          this.form.controls['organizationId'].patchValue(this.organizationsList.find(x => x.name == this.data.data.organization)?.id);
      }
    })
  }

  
  onCancel(): void {
    this.dialogRef.close(false);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 3000 });
  }

  emailToLowercase() {
    const str:String = this.form.get('email')?.value;
    if(str) {
      this.form.get('email')?.setValue(str.toLowerCase());
    }
  }

  validUserType() {

    if (!(this.form.get('email')?.value == '')
      && (this.pattern.test(this.form.get('firstName')?.value))
      && (this.pattern.test(this.form.get('lastName')?.value))) {

      const isUserTypeError = !(this.form.get('moderator')?.value || this.form.get('admin')?.value
        || this.form.get('publicUser')?.value || this.form.get('guestSpeaker')?.value);

      if (isUserTypeError) {
        return 'user-type-error';
      }

    }

    return '';

  }
}
