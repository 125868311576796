import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { LoginService } from 'src/services/login.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  // logIn: boolean = localStorage.getItem('token') != null;

  constructor(private _formBuilder: FormBuilder, private router: Router, private loginService: LoginService, private spinner: NgxSpinnerService, private snackBar: MatSnackBar, public jwtHelper: JwtHelperService,) {
    this.loginForm = this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    // Create the form
    return this.loginForm = this._formBuilder.group({
      email: [, [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  logIn() {
    this.spinner.show();
    this.emailToLowercase();
    this.loginService.logIn(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe({
        next: (result: any) => {

          let token = result.token;

          let decodedToken = this.jwtHelper.decodeToken(result.token);

          let userId = decodedToken.userId;
          let admin = decodedToken.admin;
          let moderator = decodedToken.moderator;
          let publicUser = decodedToken.publicUser;
          let alias = decodedToken.alias;

          localStorage.clear();
          localStorage.setItem('token', token);
          localStorage.setItem('userId', userId);
          localStorage.setItem('admin', admin);
          localStorage.setItem('moderator', moderator);
          localStorage.setItem('publicUser', publicUser);
          localStorage.setItem('alias', alias);


        },
        error: (error) => { console.log(error); this.spinner.hide(); this.openSnackBar("Wrong email or password.", 'Close'); },
        complete: () => { this.spinner.hide(); this.router.navigate(['main']); }

      })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 3000 });
  }

  emailToLowercase() {
    const str:String = this.loginForm.get('email')?.value;
    if(str) {
      this.loginForm.get('email')?.setValue(str.toLowerCase());
    }
  }
}
