<div class="wraper">
  <div class="header-line">
    <h2 *ngIf="formState=='NEW'">Add user</h2>
    <h2 *ngIf="formState=='EDIT'">Edit user</h2>
  </div>
  <form [formGroup]="form" #formForm="ngForm">
    <mat-card><mat-card-content>
        <section>
          <div class="inline-grid">
            <mat-label class="dialog-label">First name</mat-label>
            <mat-form-field appearance="outline" class="mr">
              <input matInput placeholder="First name" [formControlName]="'firstName'" #firstName>
            </mat-form-field>
          </div>
          <div class="inline-grid">
            <mat-label class="dialog-label">Last name</mat-label>
            <mat-form-field appearance="outline" class="mr">
              <input matInput placeholder="Last name" [formControlName]="'lastName'" #lastName>
            </mat-form-field>
          </div>
          <div class="inline-grid">
            <mat-label class="dialog-label">Email</mat-label>
            <mat-form-field appearance="outline" class="mr">
              <input matInput placeholder="Email" type="email" [formControlName]="'email'" #email
                (blur)="emailToLowercase()">
            </mat-form-field>
          </div>
          <div class="inline-grid">
            <mat-label class="dialog-label">Organization</mat-label>
            <mat-form-field appearance="outline">
              <mat-select [formControlName]="'organizationId'" #organizations>
                <mat-option *ngFor="let organization of organizationsList"
                  [value]="organization.id">{{organization.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </section>
      </mat-card-content>
    </mat-card>
    <br>
    <mat-card  [ngClass]="validUserType()">
      <mat-card-header><mat-card-subtitle>User Type</mat-card-subtitle></mat-card-header>
      <mat-card-content>
        <section>
          <mat-checkbox class="cb-margin" [color]="'primary'" [formControlName]="'moderator'" name="moderatorx"
            class="mr-cb">
            Moderator
          </mat-checkbox>
          <mat-checkbox class="cb-margin" [color]="'primary'" [formControlName]="'admin'" name="adminx" class="mr-cb">
            Admin
          </mat-checkbox>
          <mat-checkbox class="cb-margin" [color]="'primary'" [formControlName]="'publicUser'" name="publicUserx"
            class="mr-cb">
            Public user
          </mat-checkbox>
          <mat-checkbox class="cb-margin" [color]="'primary'" [formControlName]="'guestSpeaker'" name="guestSpeakerx"
            class="mr-cb">
            Guest speaker
          </mat-checkbox>
        </section>
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-button (click)="onCancel()" class="mr-btn">Cancel</button>
        <button mat-raised-button type="button" (click)="save()">Save</button>
      </mat-card-actions>
    </mat-card>


  </form>
</div>