<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" 
color = "#25bb85" type = "ball-clip-rotate" [fullScreen] = "true">
</ngx-spinner>
<div class="nav">

  <div class="container flex">
    <div class="header"></div>
    <button mat-flat-button color="primary" class="button ml" (click)="logOut()">LOG OUT</button>
  </div>

</div>
<div class="container">

  <mat-tab-group (selectedTabChange)="selectedTabChange($event)">
    <mat-tab label="Classification">

      <div style="width: 100%; text-align: center;">
        <button mat-flat-button color="primary" class="button fr" (click)="addClassification()">ADD
          CLASSIFICATION</button>
      </div>

      <table mat-table [dataSource]="dataSourceClass">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element" (click)="editClassification(element)"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element" (click)="delete(element.id, 'CLASSIFICATION')">
            <mat-icon #tooltip="matTooltip" matTooltip="Delete classification" matTooltipPosition="above"
              aria-hidden="false" aria-label="delete" fontIcon="delete_outline"></mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-tab>

    <mat-tab label="Topic">
      <div style="width: 100%;">

        <div class="inline-grid">
          <mat-label class="dialog-label">Choose classification</mat-label>
          <mat-form-field class="example-full-width" appearance="outline" class="mr">
            <input type="text" placeholder="Pick one" aria-label="Choose classification" matInput
              [(ngModel)]="classificationSelect" [formControl]="classificationControl" [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)"
              (optionSelected)="onClassificationChange($event.option.value)">
              <mat-option *ngFor="let classification of filteredClassifications | async" [value]="classification">
                {{classification.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <button mat-flat-button color="primary" class="button fr" (click)="addTopLevelTopic()">ADD TOP LEVEL
          TOPIC</button>
      </div>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
          <!-- use a disabled button to provide padding for tree leaf -->
          <button mat-icon-button disabled></button>
          <div class="topic-label" (click)="editTopic(node)">{{node.name}}</div>
          <mat-icon #tooltip="matTooltip" matTooltip="Add topic" matTooltipPosition="above" class="topic-add"
            (click)="addTopic(node.level, node.id)">add</mat-icon>
          <mat-icon #tooltip="matTooltip" matTooltip="Delete topic" matTooltipPosition="above" class="topic-delete"
            (click)="delete(node.id, 'TOPIC')">delete_outline</mat-icon>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <div class="topic-label" (click)="editTopic(node)">{{node.name}}</div>
          <mat-icon #tooltip="matTooltip" matTooltip="Add topic" matTooltipPosition="above" class="topic-add"
            (click)="addTopic(node.level, node.id)">add</mat-icon>
          <mat-icon #tooltip="matTooltip" matTooltip="Delete topic" matTooltipPosition="above" class="topic-delete"
            (click)="delete(node.id, 'TOPIC')">delete_outline</mat-icon>
        </mat-tree-node>
      </mat-tree>

    </mat-tab>

    <mat-tab label="Forum">
      <div style="width: 100%;">
        <div class="inline-grid">
          <mat-label class="dialog-label">Choose classification</mat-label>
          <mat-form-field class="example-full-width" appearance="outline" class="mr">
            <input type="text" placeholder="Pick one" aria-label="Choose classification" matInput
              [(ngModel)]="classificationSelect1" [formControl]="classificationControl1" [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)"
              (optionSelected)="onClassificationChange($event.option.value)">
              <mat-option *ngFor="let classification of filteredClassifications1 | async" [value]="classification">
                {{classification.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="inline-grid" style="padding-left: 3%;">
          <mat-label class="dialog-label">Choose topic</mat-label>
          <mat-form-field class="example-full-width" appearance="outline" class="mr">
            <input type="text" placeholder="Pick one" aria-label="Choose topic" matInput [(ngModel)]="topicSelect"
              [formControl]="topicControl" [matAutocomplete]="autoComplete">
            <mat-autocomplete autoActiveFirstOption #autoComplete="matAutocomplete"
              [displayWith]="displayFnTopic.bind(this)" (optionSelected)="onTopicChange($event.option.value)">
              <mat-option *ngFor="let topic of filteredTopics | async" [value]="topic">
                <span style="font-weight: 500;">{{topic.name}} </span> <span *ngIf="topic.path != ''"
                  style="font-size: 12px;"> ({{topic.path}})</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <button mat-flat-button color="primary" class="button fr" (click)="addForum()">ADD FORUM</button>
      </div>

      <table mat-table [dataSource]="dataSourceForum">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element" (click)="editForum(element)"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon #tooltip="matTooltip" matTooltip="Delete forum" matTooltipPosition="above" aria-hidden="false"
              aria-label="delete" fontIcon="delete_outline" (click)="delete(element.id, 'FORUM')"></mat-icon>

            <mat-icon #tooltip="matTooltip" matTooltip="Manage topics" matTooltipPosition="above" aria-hidden="false"
              aria-label="edit" fontIcon="edit" (click)="manageForum(element)"></mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-tab>

    <mat-tab label="User">
      <div style="width: 100%; text-align: center;">
        <button mat-flat-button color="primary" class="button fr" (click)="addUser()">ADD USER</button>
      </div>
      <div class="inline-grid">
        <mat-label class="dialog-label">Search users</mat-label>
        <mat-form-field appearance="outline" class="mr">
          <input matInput placeholder="First name, Last name, Email" [(ngModel)]="userSearch"
            (keyup)="filterUsers(userSearch)">
        </mat-form-field>
      </div>
      <div class="filter-style">
        <mat-label class="dialog-label">Filter</mat-label>
        <mat-form-field appearance="outline">
           <mat-select [(ngModel)]="selectedFilter" (selectionChange)="applyFilter()">
             <mat-option *ngFor="let option of filterOptions" [value]="option">{{option}}</mat-option>
           </mat-select>
         </mat-form-field>
      </div>

      <table mat-table [dataSource]="dataSourceUser">

        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef> First name </th>
          <td mat-cell *matCellDef="let element" (click)="editUser(element)"> {{element.firstName}} </td>
        </ng-container>

        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef> Last name </th>
          <td mat-cell *matCellDef="let element" (click)="editUser(element)"> {{element.lastName}}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element" (click)="editUser(element)"> {{element.email}}</td>
        </ng-container>

        <ng-container matColumnDef="approve">
          <th mat-header-cell *matHeaderCellDef> Approved Status </th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle [(ngModel)] = "element.allowLogin"
              [color]="color"
              (change) = "toAllowlogin(element.id,element.allowLogin)"
              [checked]="element.allowLogin" 
              matTooltip="Click to {{element.allowLogin?'Reject':'Approve'}}">
            </mat-slide-toggle>
          </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element" (click)="delete(element.id, 'USER')">
            <mat-icon #tooltip="matTooltip" matTooltip="Delete user" matTooltipPosition="above" aria-hidden="false"
              aria-label="delete" fontIcon="delete_outline"></mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsUser"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsUser;"></tr>
      </table>
      <mat-progress-bar *ngIf="enableApproveProgress" mode="indeterminate"></mat-progress-bar>
      <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page">
      </mat-paginator>
    </mat-tab>

    <mat-tab label="Organization">
      <div style="width: 100%; text-align: center;">
        <button mat-flat-button color="primary" class="button fr" (click)="addOrganization()">ADD ORGANIZATION</button>
      </div>

      <table mat-table [dataSource]="dataSourceOrganization">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element" (click)="editOrganization(element)"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element" (click)="delete(element.id, 'ORGANIZATION')">
            <mat-icon #tooltip="matTooltip" matTooltip="Delete organization" matTooltipPosition="above"
              aria-hidden="false" aria-label="delete" fontIcon="delete_outline"></mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-tab>

    <mat-tab label="Notification template">

      <table mat-table [dataSource]="dataSourceNotification">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element" (click)="editNotification(element)"> {{element.name}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element" (click)="delete(element.id, 'NOTIFICATION')">
            <mat-icon #tooltip="matTooltip" matTooltip="Delete template" matTooltipPosition="above" aria-hidden="false"
              aria-label="delete" fontIcon="delete_outline"></mat-icon>
          </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedNotificationColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedNotificationColumns;"></tr>
      </table>
    </mat-tab>

    <mat-tab label="Report">

      <mat-tab-group style="width: 100%">

        <mat-tab label="Per session">
          <div class="inline-grid">
            <mat-label class="dialog-label">Choose session</mat-label>
            <mat-form-field appearance="outline" class="mr">
              <input matInput placeholder="Search session" [(ngModel)]="selectedsession" [matAutocomplete]="autox"
                [formControl]="searchsessionsCtrl" placeholder="Enter 3 or more characters">
              <button *ngIf="selectedsession" matSuffix mat-icon-button aria-label="Clear" (click)="clearSelection()">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete [panelWidth]="600" #autox="matAutocomplete" (optionSelected)="onSelected()"
                [displayWith]="displayWith">
                <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                <ng-container *ngIf="!isLoading">
                  <mat-option *ngFor="let session of filteredsessions" [value]="session">
                    <span>{{session.name}}</span>
                  </mat-option>
                </ng-container>
              </mat-autocomplete>

            </mat-form-field>


          </div>

          <div>
            <button mat-flat-button color="primary" class="button fr"
              (click)="showSessionReport(selectedsession.id)">SHOW
              REPORT</button>
          </div>

          <table mat-table [dataSource]="dataSourceReport1" class="mat-table">
            <ng-container matColumnDef="sessionId">
              <th mat-header-cell *matHeaderCellDef>Session ID</th>
              <td mat-cell *matCellDef="let element">{{ element.sessionId }}</td>
            </ng-container>
        
            <ng-container matColumnDef="sessionName">
              <th mat-header-cell *matHeaderCellDef>Session Name</th>
              <td mat-cell *matCellDef="let element">{{ element.sessionName }}</td>
            </ng-container>
        
            <ng-container matColumnDef="startTime">
              <th mat-header-cell *matHeaderCellDef>Start Time</th>
              <td mat-cell *matCellDef="let element">{{ element.startTime | date }}</td>
            </ng-container>
        
            <ng-container matColumnDef="enrolledUsers">
              <th mat-header-cell *matHeaderCellDef>Enrolled Users</th>
              <td mat-cell *matCellDef="let element">{{ element.enrolledUsers }}</td>
            </ng-container>
        
            <ng-container matColumnDef="joinedUsers">
              <th mat-header-cell *matHeaderCellDef>Joined Users</th>
              <td mat-cell *matCellDef="let element">{{ element.joinedUsers }}</td>
            </ng-container>
        
            <ng-container matColumnDef="chats">
              <th mat-header-cell *matHeaderCellDef>Chats</th>
              <td mat-cell *matCellDef="let element">{{ element.chats === null ? 'N/A' : element.chats }}</td>
            </ng-container>
        
            <ng-container matColumnDef="shares">
              <th mat-header-cell *matHeaderCellDef>Shares</th>
              <td mat-cell *matCellDef="let element">{{ element.shares === null ? 'N/A' : element.shares }}</td>
            </ng-container>
        
            <ng-container matColumnDef="raiseHandRequests">
              <th mat-header-cell *matHeaderCellDef>Raise Hand Requests</th>
              <td mat-cell *matCellDef="let element">{{ element.raiseHandRequests }}</td>
            </ng-container>
        
            <ng-container matColumnDef="positiveFeedbacks">
              <th mat-header-cell *matHeaderCellDef>Positive Feedbacks</th>
              <td mat-cell *matCellDef="let element">{{ element.positiveFeedbacks }}</td>
            </ng-container>
        
            <ng-container matColumnDef="negativeFeedbacks">
              <th mat-header-cell *matHeaderCellDef>Negative Feedbacks</th>
              <td mat-cell *matCellDef="let element">{{ element.negativeFeedbacks }}</td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumnsReport"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsReport;"></tr>
          </table>

        </mat-tab>
        
        <mat-tab label="Per moderator">

          <div class="inline-grid" style="width: 30%; margin-right: 5%;">
            <mat-label class="dialog-label">Moderator</mat-label>
            <mat-form-field class="example-full-width" appearance="outline">
              <input type="text" matInput [formControl]="searchModeratorsCtrl" [(ngModel)]="moderatorValue"
                [matAutocomplete]="autoM">
              <mat-autocomplete #autoM="matAutocomplete" [displayWith]="displayFnModerator">
                <mat-option *ngFor="let option of filteredOptionsModerator | async" [value]="option">
                  {{option.firstName}} {{option.lastName}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="inline-grid" style="width: 30%; margin-right: 5%;">
            <mat-label class="dialog-label">From</mat-label>
            <mat-form-field appearance="outline" class="mr">
              <input matInput [matDatepicker]="time1ModeratorX" placeholder="Choose date" readonly
                [(ngModel)]="time1Moderator">
              <mat-datepicker-toggle matSuffix [for]="time1ModeratorX"></mat-datepicker-toggle>
              <mat-datepicker #time1ModeratorX disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="inline-grid" style="width: 30%;">
            <mat-label class="dialog-label">To</mat-label>
            <mat-form-field appearance="outline" class="mr">
              <input matInput [matDatepicker]="time2ModeratorX" placeholder="Choose date" readonly
                [(ngModel)]="time2Moderator">
              <mat-datepicker-toggle matSuffix [for]="time2ModeratorX"></mat-datepicker-toggle>
              <mat-datepicker #time2ModeratorX disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>

          <div>
            <button mat-flat-button color="primary" class="button fr" (click)="showModeratorsReport()">SHOW
              REPORT</button>
          </div>
          <table mat-table [dataSource]="dataSourceReport2" class="mat-table">
            <ng-container matColumnDef="sessionId">
              <th mat-header-cell *matHeaderCellDef>Session ID</th>
              <td mat-cell *matCellDef="let element">{{ element.sessionId }}</td>
            </ng-container>
        
            <ng-container matColumnDef="sessionName">
              <th mat-header-cell *matHeaderCellDef>Session Name</th>
              <td mat-cell *matCellDef="let element">{{ element.sessionName }}</td>
            </ng-container>
        
            <ng-container matColumnDef="startTime">
              <th mat-header-cell *matHeaderCellDef>Start Time</th>
              <td mat-cell *matCellDef="let element">{{ element.startTime | date }}</td>
            </ng-container>
        
            <ng-container matColumnDef="enrolledUsers">
              <th mat-header-cell *matHeaderCellDef>Enrolled Users</th>
              <td mat-cell *matCellDef="let element">{{ element.enrolledUsers }}</td>
            </ng-container>
        
            <ng-container matColumnDef="joinedUsers">
              <th mat-header-cell *matHeaderCellDef>Joined Users</th>
              <td mat-cell *matCellDef="let element">{{ element.joinedUsers }}</td>
            </ng-container>
        
            <ng-container matColumnDef="chats">
              <th mat-header-cell *matHeaderCellDef>Chats</th>
              <td mat-cell *matCellDef="let element">{{ element.chats === null ? 'N/A' : element.chats }}</td>
            </ng-container>
        
            <ng-container matColumnDef="shares">
              <th mat-header-cell *matHeaderCellDef>Shares</th>
              <td mat-cell *matCellDef="let element">{{ element.shares === null ? 'N/A' : element.shares }}</td>
            </ng-container>
        
            <ng-container matColumnDef="raiseHandRequests">
              <th mat-header-cell *matHeaderCellDef>Raise Hand Requests</th>
              <td mat-cell *matCellDef="let element">{{ element.raiseHandRequests }}</td>
            </ng-container>
        
            <ng-container matColumnDef="positiveFeedbacks">
              <th mat-header-cell *matHeaderCellDef>Positive Feedbacks</th>
              <td mat-cell *matCellDef="let element">{{ element.positiveFeedbacks }}</td>
            </ng-container>
        
            <ng-container matColumnDef="negativeFeedbacks">
              <th mat-header-cell *matHeaderCellDef>Negative Feedbacks</th>
              <td mat-cell *matCellDef="let element">{{ element.negativeFeedbacks }}</td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumnsReport"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsReport;"></tr>
          </table>
        </mat-tab>

        <mat-tab label="Per forum">

          <div class="inline-grid" style="width: 30%; margin-right: 5%;">
            <mat-label class="dialog-label">Forum</mat-label>
            <mat-form-field class="example-full-width" appearance="outline">
              <input type="text" matInput [formControl]="searchForumsCtrl" [(ngModel)]="forumValue"
                [matAutocomplete]="autoF">
              <mat-autocomplete #autoF="matAutocomplete" [displayWith]="displayFnForum">
                <mat-option *ngFor="let option of filteredOptionsForum | async" [value]="option">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="inline-grid" style="width: 30%; margin-right: 5%;">
            <mat-label class="dialog-label">From</mat-label>
            <mat-form-field appearance="outline" class="mr">
              <input matInput [matDatepicker]="time1ForumX" placeholder="Choose date" readonly [(ngModel)]="time1Forum">
              <mat-datepicker-toggle matSuffix [for]="time1ForumX"></mat-datepicker-toggle>
              <mat-datepicker #time1ForumX disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="inline-grid" style="width: 30%;">
            <mat-label class="dialog-label">To</mat-label>
            <mat-form-field appearance="outline" class="mr">
              <input matInput [matDatepicker]="time2ForumX" placeholder="Choose date" readonly [(ngModel)]="time2Forum">
              <mat-datepicker-toggle matSuffix [for]="time2ForumX"></mat-datepicker-toggle>
              <mat-datepicker #time2ForumX disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>

          <div>
            <button mat-flat-button color="primary" class="button fr" (click)="showForumReport()">SHOW
              REPORT</button>
          </div>
          <table mat-table [dataSource]="dataSourceReport3" class="mat-table">
            <ng-container matColumnDef="sessionId">
              <th mat-header-cell *matHeaderCellDef>Session ID</th>
              <td mat-cell *matCellDef="let element">{{ element.sessionId }}</td>
            </ng-container>
        
            <ng-container matColumnDef="sessionName">
              <th mat-header-cell *matHeaderCellDef>Session Name</th>
              <td mat-cell *matCellDef="let element">{{ element.sessionName }}</td>
            </ng-container>
        
            <ng-container matColumnDef="startTime">
              <th mat-header-cell *matHeaderCellDef>Start Time</th>
              <td mat-cell *matCellDef="let element">{{ element.startTime | date }}</td>
            </ng-container>
        
            <ng-container matColumnDef="enrolledUsers">
              <th mat-header-cell *matHeaderCellDef>Enrolled Users</th>
              <td mat-cell *matCellDef="let element">{{ element.enrolledUsers }}</td>
            </ng-container>
        
            <ng-container matColumnDef="joinedUsers">
              <th mat-header-cell *matHeaderCellDef>Joined Users</th>
              <td mat-cell *matCellDef="let element">{{ element.joinedUsers }}</td>
            </ng-container>
        
            <ng-container matColumnDef="chats">
              <th mat-header-cell *matHeaderCellDef>Chats</th>
              <td mat-cell *matCellDef="let element">{{ element.chats === null ? 'N/A' : element.chats }}</td>
            </ng-container>
        
            <ng-container matColumnDef="shares">
              <th mat-header-cell *matHeaderCellDef>Shares</th>
              <td mat-cell *matCellDef="let element">{{ element.shares === null ? 'N/A' : element.shares }}</td>
            </ng-container>
        
            <ng-container matColumnDef="raiseHandRequests">
              <th mat-header-cell *matHeaderCellDef>Raise Hand Requests</th>
              <td mat-cell *matCellDef="let element">{{ element.raiseHandRequests }}</td>
            </ng-container>
        
            <ng-container matColumnDef="positiveFeedbacks">
              <th mat-header-cell *matHeaderCellDef>Positive Feedbacks</th>
              <td mat-cell *matCellDef="let element">{{ element.positiveFeedbacks }}</td>
            </ng-container>
        
            <ng-container matColumnDef="negativeFeedbacks">
              <th mat-header-cell *matHeaderCellDef>Negative Feedbacks</th>
              <td mat-cell *matCellDef="let element">{{ element.negativeFeedbacks }}</td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumnsReport"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsReport;"></tr>
          </table>
        </mat-tab>
      </mat-tab-group>

    </mat-tab>
  </mat-tab-group>
</div>