<div class="wraper">
  <h3 *ngIf="result.section=='FORUM'">Delete forum</h3>
  <h3 *ngIf="result.section=='CLASSIFICATION'">Delete clasification</h3>
  <h3 *ngIf="result.section=='TOPIC'">Delete topic</h3>
  <h3 *ngIf="result.section=='USER'">Delete user</h3>
  <h3 *ngIf="result.section=='ORGANIZATION'">Delete organization</h3>


  <div class="save-cancel">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-raised-button type="button" (click)="delete()">Delete</button>
  </div>
  
</div>