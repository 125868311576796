<div class="wraper">
  <div class="header-line">
    <h2 *ngIf="formState=='NEW'">Add forum</h2>
    <h2 *ngIf="formState=='EDIT'">Edit forum</h2>
  </div>
  <form [formGroup]="form" #formForm="ngForm">
    <div>
      <section>
        <div class="inline-grid">
          <mat-label class="dialog-label">Choose classification</mat-label>
          <mat-form-field class="example-full-width" appearance="outline" class="mr">
            <input type="text" placeholder="Pick one" aria-label="Choose classification" matInput
              [(ngModel)]="classificationSelect" [formControl]="classificationControl" [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)"
              (optionSelected)="onClassificationChange($event.option.value)">
              <mat-option *ngFor="let classification of filteredClassifications | async" [value]="classification">
                {{classification.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="inline-grid">
          <mat-label class="dialog-label">Topics</mat-label>
          <mat-form-field appearance="outline" class="mr">
            <mat-select [formControlName]="'topicIds'" #topicIds multiple>
              <mat-select-trigger>
                {{getNameOfFirstTopic() || ''}}
                <span *ngIf="(topicIds.value?.length || 0) > 1" class="example-additional-selection">
                  (+{{(topicIds.value?.length || 0) - 1}} {{topicIds.value?.length === 2 ? 'other' : 'others'}})
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let topic of topicList" [value]="topic.id" (click)="onOptionClick(topic)">{{topic.name}}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- <div class="inline-grid">
          <mat-label class="dialog-label">Choose topic</mat-label>
          <mat-form-field class="example-full-width" appearance="outline" class="mr">
            <input type="text" placeholder="Pick one" aria-label="Choose topic" matInput 
              [formControlName]="'topics'" #topics [matAutocomplete]="autoComplete">
            <mat-autocomplete autoActiveFirstOption #autoComplete="matAutocomplete"
              [displayWith]="displayFnTopic.bind(this)">

              <mat-select-trigger>
                {{topics.value?.[0] || ''}}
                <span *ngIf="(topics.value?.length || 0) > 1" class="example-additional-selection">
                  (+{{(topics.value?.length || 0) - 1}} {{topics.value?.length === 2 ? 'other' : 'others'}})
                </span>
              </mat-select-trigger>

              <mat-option *ngFor="let topic of filteredTopics | async" [value]="topic">
                <span style="font-weight: 500;">{{topic.name}} </span> <span *ngIf="topic.path != ''"
                  style="font-size: 12px;"> ({{topic.path}})</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div> -->

        <!-- <div class="inline-grid">
          <mat-label class="dialog-label">topics</mat-label>
          <mat-form-field appearance="outline" class="mr">
            <mat-select [formControlName]="'topics'" #topics multiple>
              <mat-select-trigger>
                {{topics.value?.[0] || ''}}
                <span *ngIf="(topics.value?.length || 0) > 1" class="example-additional-selection">
                  (+{{(topics.value?.length || 0) - 1}} {{topics.value?.length === 2 ? 'other' : 'others'}})
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let topic of topicsList" [value]="topic.firstName">{{topic.firstName}}
                {{topic.lastName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
      </section>

    </div>
    <div class="save-cancel">
      <button mat-button (click)="onCancel()" class="mr-btn">Cancel</button>
      <button mat-raised-button type="button" (click)="save()">Save</button>
    </div>
  </form>
</div>