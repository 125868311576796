import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Result } from 'src/model/result';


@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  result: Result = new Result();


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService) {

      this.result.id = data.id;
      this.result.section = data.section;

  }

  ngOnInit() {
  }

  delete(){
    this.result.isDelete = true;
    this.dialogRef.close(this.result);
  }

  onCancel(): void {
    this.result.isDelete = false;
    this.dialogRef.close(this.result);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 3000 });
  }
}
