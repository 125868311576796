import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router, private dialog: MatDialog) { }

  handleUnauthorizedError(): void {
    // Close any open dialog
    this.dialog.closeAll();

    // Redirect to login page
    this.router.navigate(['']);
  }

  openErrorDialog(message: string): void {
    // Close any open dialog
    this.dialog.closeAll();

  }
}
