import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClassificationService } from 'src/services/classification.service';
import { ForumService } from 'src/services/forum.service';
import { TopicService } from 'src/services/topic.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ClassificationDialogComponent } from './classification-dialog/classification-dialog.component';
import { Classification } from 'src/model/classification';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Forum } from 'src/model/forum';
import { ForumDialogComponent } from './forum-dialog/forum-dialog.component';
import { Router } from '@angular/router';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { Result } from 'src/model/result';
import { Topic } from 'src/model/topic';
import { TopicDialogComponent } from './topic-dialog/topic-dialog.component';
import { UpdateUser, User } from 'src/model/User';
import { UserService } from 'src/services/user.service';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { OrganizationService } from 'src/services/organization.service';
import { OrganizationDialogComponent } from './organization-dialog/organization-dialog.component';
import { Organization } from 'src/model/organization';
import { OrganizationUpdate } from 'src/model/organizationUpdate';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, finalize, map, Observable, of, startWith, switchMap, tap } from 'rxjs';
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';
import { NotificationService } from 'src/services/notification.service';
import { NotificationList } from 'src/model/notification';
import { SessionService } from 'src/services/session.service';
import { SessionList } from 'src/model/sessionNew';
import { MatPaginator } from '@angular/material/paginator';
import { ReportService } from 'src/services/report.service';
import { Moderator } from 'src/model/moderator';
import { Report } from 'src/model/report';
import { ForumTopicDialogComponent } from './forum-topic-dialog/forum-topic-dialog.component';
import { ThemePalette } from '@angular/material/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

interface ExampleFlatNode {
  expandable: boolean;
  id: number;
  name: string;
  level: number;
}

interface TopicList {
  id: number;
  name: string;
  path: string;
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})


export class MainComponent implements OnInit {
  dataSourceClass: [] = [];
  dataSourceForum: [] = [];
  dataSourceUser!: MatTableDataSource<User>;
  dataSourceOrganization: [] = [];
  dataSourceNotification: [] = [];
  displayedNotificationColumns: string[] = ['name'];
  displayedColumns: string[] = ['name', 'delete'];
  displayedColumnsUser: string[] = ['firstName', 'lastName', 'email', 'approve', 'delete'];

  classificationControl = new FormControl<string | Classification>('');
  classifications: Classification[] = [];
  filteredClassifications!: Observable<Classification[]>;

  topicControl = new FormControl<string | TopicList>('');
  topics: TopicList[] = [];
  filteredTopics!: Observable<TopicList[]>;
  sessionsList: SessionList[] = [];

  filteredOptionsModerator!: Observable<Moderator[]>;
  filteredOptionsForum!: Observable<Forum[]>;

  moderatorsList: Moderator[] = [];
  selectedModeratorsList: Moderator[] = [];
  forumsList: Forum[] = [];
  selectedForumsList: Forum[] = [];
  time1Moderator:string = '';
  time2Moderator:string = '';
  time1Forum:string = '';
  time2Forum:string = '';

  moderatorValue!: Moderator;
  forumValue!: Forum;

  filterOptions: string[] = ['All','Waiting','Approved'];

  enableApproveProgress: boolean = false;

  color: ThemePalette = 'primary';
  
  selectedFilter: any = this.filterOptions[0];

  
  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.subtopics && node.subtopics.length > 0,
      id: node.id,
      name: node.name,
      level: level
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.subtopics
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  classificationSelect: Classification = {} as Classification;

  classificationSelect1: Classification = {} as Classification;
  classificationControl1 = new FormControl<string | Classification>('');
  filteredClassifications1!: Observable<Classification[]>;
  selectedTabIndex: number = 0;

  topicSelect: TopicList = {} as TopicList;

  topicList: TopicList[] = [];
  topicListFromTree: Topic[] = [];

  searchsessionsCtrl = new FormControl();
  filteredsessions: any;

  searchModeratorsCtrl = new FormControl();
  filteredModerators: any;

  searchForumsCtrl = new FormControl();
  filteredForums: any;

  isLoading = false;
  errorMsg!: string;
  minLengthTerm = 3;
  selectedsession: any = "";
  selectedModerator: any = "";
  selectedForum: any = "";

  userSearch: string = '';
  originalUserData: User[] = [];

  sessionId: any;

  selectedTopicId: number = 0;

  displayedColumnsReport: string[] = [
    'sessionId', 'sessionName', 'startTime', 'enrolledUsers', 'joinedUsers', 'chats', 'shares', 'raiseHandRequests', 'positiveFeedbacks', 'negativeFeedbacks'
  ];
  dataSourceReport1 = new MatTableDataSource<Report>([]);
  dataSourceReport2 = new MatTableDataSource<Report>([]);
  dataSourceReport3 = new MatTableDataSource<Report>([]);

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;



  constructor(private classificationService: ClassificationService, private topicService: TopicService, private forumService: ForumService, private notificationService: NotificationService,
    private userService: UserService, private organizationService: OrganizationService, private sessionService: SessionService, private reportService: ReportService, private snackBar: MatSnackBar, private spinner: NgxSpinnerService, public dialog: MatDialog, private router: Router) {

  }

  ngOnInit() {
    this.checkToken();
    this.getAllClassifications();
    this.getAllTopics();
    // this.getAllForums();
    this.getAllUsers();
    this.getAllOrganizations();
    // this.getAllTopicsForForum(1);
    this.getAllNotifications();

    this.getModerators();
    this.getForums();

    this.sessionAutocomplete();
   // this.getModerators(userId, time1, time2);

   this.filteredOptionsModerator = this.searchModeratorsCtrl.valueChanges.pipe(
    startWith(''),
    map(value => {
      const name = typeof value === 'string' ? value : value?.name;
      return name ? this._filterModerator(name as string) : this.moderatorsList.slice();
    }),
  );

  this.filteredOptionsForum = this.searchForumsCtrl.valueChanges.pipe(
    startWith(''),
    map(value => {
      const name = typeof value === 'string' ? value : value?.name;
      return name ? this._filterForum(name as string) : this.forumsList.slice();
    }),
  );
   
  }

  logOut() {
    localStorage.setItem('token', '');
    localStorage.setItem('userId', '');
    localStorage.setItem('admin', '');
    localStorage.setItem('moderator', '');
    localStorage.setItem('publicUser', '');
    localStorage.setItem('alias', '');
    this.router.navigate(['']);
  }

  checkToken(){
    let token = localStorage.getItem('token');
    if(!token){
      this.logOut();
    }
  }

  displayFnModerator(moderator: Moderator): string {
    return moderator ? moderator.firstName + ' ' + moderator.lastName : '';
  }

  private _filterModerator(name: string): Moderator[] {
    const filterValue = name.toLowerCase();

    return this.moderatorsList.filter(option => option.firstName.toLowerCase().includes(filterValue) || option.lastName.toLowerCase().includes(filterValue));
  }

  displayFnForum(forum: Forum): string {
    return forum ? forum.name : '';
  }

  private _filterForum(name: string): Forum[] {
    const filterValue = name.toLowerCase();

    return this.forumsList.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  onSelected() {
    console.log(this.selectedsession);
    this.selectedsession = this.selectedsession;
  }

  displayWith(value: any) {    
    return value?.name;
  }

  clearSelection() {
    this.selectedsession = "";
    this.filteredsessions = [];
  }

  filterUsers(userSearch: string){

    this.dataSourceUser.data = this.originalUserData;

    this.dataSourceUser.data = this.dataSourceUser.data.filter( x => 
      x.email?.toLowerCase().includes(userSearch.toLowerCase()) || 
      x.firstName?.toLowerCase().includes(userSearch.toLowerCase()) ||
      x.lastName?.toLowerCase().includes(userSearch.toLowerCase()));

    if(this.selectedFilter==='Waiting') {
      this.dataSourceUser.data = this.dataSourceUser.data.filter( x => !x.allowLogin);
    } else if(this.selectedFilter==="Approved") {
      this.dataSourceUser.data = this.dataSourceUser.data.filter( x => x.allowLogin);
    }  
  }

  sessionAutocomplete(){

    this.searchsessionsCtrl.valueChanges
    .pipe(
      filter(res => {
        return res !== null && res.length >= this.minLengthTerm
      }),
      distinctUntilChanged(),
      debounceTime(300),
      tap(() => {
        this.errorMsg = "";
        this.filteredsessions = [];
        this.isLoading = true;
      }),
        switchMap((value: string) => this.sessionService.getSessionList(value)
        .pipe(
          finalize(() => {
            this.isLoading = false
          }),
        )
      )
    )
    .subscribe((data: any) => {
      this.filteredsessions = data;
    });
}


  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  getAllClassifications() {
    this.spinner.show();
    this.classificationService.getClassificationList()
      .subscribe({
        next: (result: any) => {
          //let activeClassifications = result.filter((x: Classification) => x.deleted == false);          
          this.dataSourceClass = result; //activeClassifications;
          this.classifications = result; //activeClassifications;
          //console.log(this.classifications);
          
          this.filteredClassifications = this.classificationControl.valueChanges.pipe(
            startWith(''),
            map(value => {
              const name = typeof value === 'string' ? value : value?.name;
              return name ? this._filter(name as string) : this.classifications.slice();
            }));
          
          this.filteredClassifications1 = this.classificationControl1.valueChanges.pipe(
            startWith(''),
            map(value => {
              const name = typeof value === 'string' ? value : value?.name;
              return name ? this._filter(name as string) : this.classifications.slice();
            }));
        },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); }

      })
  }

  onClassificationChange(selectedClassification: Classification){
    this.spinner.show();
    if(this.selectedTabIndex==2) {
      this.topicControl.setValue('');
      this.getAllTopicsForForum(selectedClassification.id);
      this.classificationSelect1 = selectedClassification;
    } else {
      this.getTopicsByClassificationId(selectedClassification.id);
      this.classificationSelect = selectedClassification;
    }
  }

  onTopicChange(selectedTopic: TopicList){
    this.getForumByTopicId(selectedTopic.id);
  }

  onSessionChange(session: any){
    console.log(session);
  }

  addClassification() {
    const dialogRef = this.dialog.open(ClassificationDialogComponent, {
      width: '800px',
      data: {
        data: new Classification(),
        formState: 'NEW'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllClassifications();
      }
    });
  }

  editClassification(data: Classification) {
    const dialogRef = this.dialog.open(ClassificationDialogComponent, {
      width: '800px',
      data: {
        data: data,
        formState: 'EDIT'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllClassifications();
      }
    });
  }

  deleteClassification(id: number) {
    this.spinner.show();
    this.classificationService.deleteClassification(id)
      .subscribe({
        next: (result: any) => { },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); this.openSnackBar("Successfully deleted.", 'Close'); this.getAllClassifications(); }

      })
  }

  getAllUsers() {
    this.selectedFilter = this.filterOptions[0];
    this.userSearch = '';
    this.spinner.show();
    this.userService.getUserList()
      .subscribe({
        next: (result: any) => {

          this.dataSourceUser = new MatTableDataSource(result);
          this.originalUserData = this.dataSourceUser.data.slice();
          this.dataSourceUser.paginator = this.paginator;
        },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { 
          this.spinner.hide(); 
          this.enableApproveProgress = false;
        }
      })
  }

  addUser() {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      width: '800px',
      data: {
        data: new User(),
        formState: 'NEW'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllUsers();
      }
    });
  }

  editUser(data: User) {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      width: '800px',
      data: {
        data: data,
        formState: 'EDIT'
      },

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllUsers();
      }
    });
  }

  delete(id: number, section: string) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {
        id: id,
        section: section
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result: Result) => {
        if (result.isDelete) {
          if (result.section == 'FORUM')
            this.deleteForum(result.id);
          if (result.section == 'CLASSIFICATION')
            this.deleteClassification(result.id);
          if (result.section == 'TOPIC')
            this.deleteTopic(result.id);
          if (result.section == 'ORGANIZATION')
            this.deleteOrganization(result.id);
          if (result.section == 'USER')
            this.deleteUser(result.id);
        }
      }
    });
  }

  convertTreeToList(root: any) {
    let stack = [];
    let array: any[] = [];
    let hashMap = {};
    stack = root;

    while (stack.length !== 0) {
      var node = stack.pop();

      if (node.subtopics.length == 0) {
        array.push(node)
      } else {
        array.push(node)
        for (var i = node.subtopics.length - 1; i >= 0; i--) {
          stack.push(node.subtopics[i]);
        }
      }
    }
    return array;
  }

  createPath(topicList: Topic[]) {
    let array: TopicList[] = []

    topicList.forEach(element => {
      let level = element.level;
      let parentId = element.parentId;
      let name = element.name;
      let id = element.id;
      let path = element.name;

      for (let i = level; i > 0; i--) {
        let parentTopic = topicList.find(x => x.id == parentId);
        if (parentTopic) {
          parentId = parentTopic.parentId;
          path = parentTopic.name + '/' + path;
        }
      }

      if (path == name)
        path = '';

      let newTopic: TopicList = {
        id: id,
        name: name,
        path: path
      }

      array.push(newTopic);
    });

    return array;

  }

  getTopicsByClassificationId(classificationId:number) {  
    this.spinner.show();  
    this.topicService.getFullTree(classificationId)
      .subscribe({
        next: (result: any) => {
          this.dataSource.data = result;          
        },
        error: (error) => { 
          console.log(error); 
          this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); 
          this.spinner.hide(); 
        },
        complete: () => { this.spinner.hide(); }
      })
  }


  getAllTopics() {    

    if(this.classificationSelect.id) {
      this.spinner.show();
      this.topicService.getFullTree(this.classificationSelect.id)
      .subscribe({
        next: (result: any) => {
          this.dataSource.data = result;

          // this.topicListFromTree = this.convertTreeToList(result);
          // this.topicList = this.createPath(this.topicListFromTree);
          
          // this.filteredTopics = this.topicControl.valueChanges.pipe(
          //   startWith(''),
          //   map(value => {
          //     const name = typeof value === 'string' ? value : value?.name;
          //     return name ? this._filterTopic(name as string) : this.topicList.slice();

          //   })
          // );


          
        },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); }

      })
    }    
  }

  getAllTopicsForForum(classificationId:number) {
    this.spinner.show();
    this.topicService.getFullTree(classificationId)
      .subscribe({
        next: (result: any) => {
         // this.dataSource.data = result;

          this.topicListFromTree = this.convertTreeToList(result);
          this.topicList = this.createPath(this.topicListFromTree);
          
          this.filteredTopics = this.topicControl.valueChanges.pipe(
            startWith(''),
            map(value => {
              const name = typeof value === 'string' ? value : value?.name;
              return name ? this._filterTopic(name as string) : this.topicList.slice();

            })
          );


          
        },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); }

      })
  }

  addTopLevelTopic() {
    const dialogRef = this.dialog.open(TopicDialogComponent, {
      width: '800px',
      data: {
        data: {
          name: '',
          classificationId: null,
          parentId: 0,
          level: 0
        },
        formState: 'NEW'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);

      if (result?.data) {
        this.classificationSelect.id = result.data;
        this.setClassificationById(result.data);
        this.getAllTopics();
      }
    });
  }

  setClassificationById(classificationId: number): void {
    const selectedClassification = this.classifications.find(c => c.id === classificationId);
    if (selectedClassification) {
      this.classificationSelect = selectedClassification;
    }
  }

  addTopic(level: number, parentId: number) {
    const dialogRef = this.dialog.open(TopicDialogComponent, {
      width: '800px',
      data: {
        data: {
          name: '',
          classificationId: null,
          parentId: parentId,
          level: +level + 1
        },
        formState: 'NEW'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      
      if (result) {
        this.getAllTopics();
      }
    });
  }

  editTopic(data: Topic) {
    const dialogRef = this.dialog.open(TopicDialogComponent, {
      width: '800px',
      data: {
        data: data,
        formState: 'EDIT'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllTopics();
      }
    });
  }
  deleteTopic(id: number) {
    this.spinner.show();
    this.topicService.deleteTopic(id)
      .subscribe({
        next: (result: any) => {},
        error: (error) => { console.log(error.error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); this.openSnackBar("Successfully deleted.", 'Close'); this.getAllTopics(); }

      })
  }


  // getAllForums() {
  //   this.forumService.getForumListByTopic(this.topicSelect.id)
  //     .subscribe({
  //       next: (result: any) => {
  //         this.dataSourceForum = result;
  //       },
  //       error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
  //       complete: () => { this.spinner.hide();  }

  //     })
  // }

  addForum() {
    if(this.classificationSelect1.id) {
      const dialogRef = this.dialog.open(ForumDialogComponent, {
        width: '800px',
        data: {
          data: new Forum(),
          classification : this.classificationSelect1,
          topicId : this.selectedTopicId,
          formState: 'NEW'
        },
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getForums();
        }
      });
    } else {
      this.openSnackBar("Choose classification", 'Close');
    }
  }

  editForum(data: Forum) {
    const topic = data.topics;
    const dialogRef = this.dialog.open(ForumDialogComponent, {
      width: '800px',
      data: {
        data: data,
        classification : this.classificationSelect1,
        topicId : this.selectedTopicId,
        formState: 'EDIT'
      },
    });

    dialogRef.afterClosed().subscribe(topicId => {
      if (topicId) {
        this.getForumByTopicId(topicId);
      }
    });
  }

  manageForum(data: Forum) {
    const dialogRef = this.dialog.open(ForumTopicDialogComponent, {
      width: '800px',
      data: {
        data: data,
        formState: 'EDIT'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getForums();
      }
    });
  }

  deleteForum(id: number) {
    this.spinner.show();
    this.forumService.deleteForum(id)
      .subscribe({
        next: (result: any) => { },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); this.openSnackBar("Successfully deleted.", 'Close'); this.getForums();  }

      })
  }

  getAllOrganizations() {
    this.spinner.show();
    this.organizationService.getOrganizationList()
      .subscribe({
        next: (result: any) => {
          this.dataSourceOrganization = result;
        },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); }

      })
  }

  addOrganization() {
    const dialogRef = this.dialog.open(OrganizationDialogComponent, {
      width: '800px',
      data: {
        data: new Organization(),
        formState: 'NEW'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllOrganizations();
      }
    });
  }

  editOrganization(data: OrganizationUpdate) {
    const dialogRef = this.dialog.open(OrganizationDialogComponent, {
      width: '800px',
      data: {
        data: data,
        formState: 'EDIT'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllOrganizations();
      }
    });
  }

  deleteOrganization(id: number) {
    this.spinner.show();
    this.organizationService.deleteOrganization(id)
      .subscribe({
        next: (result: any) => { },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); this.openSnackBar("Successfully deleted.", 'Close'); this.getAllOrganizations();  }

      })
  }

  deleteUser(id: number) {
    this.spinner.show();
    this.userService.deleteUser(id)
      .subscribe({
        next: (result: any) => { },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); this.openSnackBar("Successfully deleted.", 'Close'); this.getAllUsers();  }

      })
  }

  getAllNotifications() {
    this.spinner.show();
    this.notificationService.getNotificationList()
      .subscribe({
        next: (result: any) => {
          this.dataSourceNotification = result;          
        },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); }

      })
  }

  editNotification(data: NotificationList) {

    const dialogRef = this.dialog.open(NotificationDialogComponent, {
      width: '800px',
      data: {
        data: data,
        formState: 'EDIT'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllNotifications();
      }
    });
  }

  showSessionReport(sessionId: number){
  //   this.spinner.show();
  //   this.reportService.getPerSession(sessionId)
  //   .subscribe({
  //      next: (result: any) =>{
  //       console.log(result);
  //       this.dataSourceReport1 = new MatTableDataSource(result);
  //     },
  //     error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
  //     complete: () => { this.spinner.hide(); }

  // })
}
showModeratorsReport(){
//   this.spinner.show();
//   this.reportService.getPerModerator(this.moderatorValue.id, this.time1Moderator, this.time2Moderator)
//   .subscribe({
//      next: (result: any) =>{
//       console.log(result);
//       this.dataSourceReport2 = new MatTableDataSource(result);
//     },
//     error: (error) => { console.log(error); this.openSnackBar((error && fined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
//     complete: () => { this.spinner.hide(); }

// })
}
showForumReport(){
//   this.spinner.show();
//   this.reportService.getPerForum(this.forumValue.id, this.time1Forum, this.time2Forum)
//   .subscribe({
//      next: (result: any) =>{
//       console.log(result);
//       this.dataSourceReport3 = new MatTableDataSource(result);
//     },
//     error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
//     complete: () => { this.spinner.hide(); }

// })
}

getModerators(){
  this.spinner.show();
  this.forumService.getForumModerators()
    .subscribe({
    next: (result: any) => {
      
    this.moderatorsList = result;
    },
    error: (error) => { console.log(error); this.openSnackBar("Error: " + (error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
    complete: () => { this.spinner.hide();  }

  })
}

getForums(){
  this.spinner.show();
  this.forumService.getAllForumList()
    .subscribe({
    next: (result: any) => {
     
    this.forumsList = result;
    },
    error: (error) => { console.log(error); this.openSnackBar("Error: " + (error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
    complete: () => { this.spinner.hide(); }

  })
}

getForumByTopicId(topicId:number){
  this.spinner.show();
  this.selectedTopicId = topicId;
  this.forumService.getForumByTopicId(topicId)
    .subscribe({
    next: (result: any) => {
      this.dataSourceForum = result;
    },
    error: (error) => { 
      console.log(error); 
      this.openSnackBar("Error: " + (error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); 
      this.spinner.hide(); 
    },
    complete: () => { this.spinner.hide(); }

  })
}

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 3000 });
  }

  displayFn(classification: Classification): string {
    return classification != null ? classification.name : '';
  }

  displayFnTopic(topic: TopicList): string {
    return topic != null ? topic.name : '';
  }
  private _filter(name: string): Classification[] {
    let filterValue = name.toLowerCase();

    return this.classifications.filter(classification => classification.name.toLowerCase().includes(filterValue));
  }

  private _filterTopic(name: string): TopicList[] {
    let filterValue = name.toLowerCase();

    return this.topicList.filter(topic => topic.name.toLowerCase().includes(filterValue));
  }

  applyFilter() {

    if(null === this.userSearch || ""===this.userSearch) {
      this.dataSourceUser.data = this.originalUserData;
    } else {
      this.filterUsers(this.userSearch);
    }
    
    if(this.selectedFilter==='Waiting') {
      this.dataSourceUser.data = this.dataSourceUser.data.filter( x => !x.allowLogin);
    } else if(this.selectedFilter==="Approved") {
      this.dataSourceUser.data = this.dataSourceUser.data.filter( x => x.allowLogin);
    } 
    
  }

  toAllowlogin(id:number,allowLogin: boolean) {
    this.enableApproveProgress = true;
    const updateUser:UpdateUser = new UpdateUser();
    updateUser.id = id;
    updateUser.allowLogin = allowLogin;

    this.spinner.show();
    this.userService.updateAllowloginUser(updateUser)
      .subscribe({
        next: (result: any) => { 

        },
        error: (error) => { 
          console.log(error); 
          this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); 
          this.spinner.hide(); 
          this.enableApproveProgress = false;
        },
        complete: () => { 
          this.spinner.hide(); this.openSnackBar("Successfully Updated.", 'Close'); 
          this.getAllUsers(); 
        }
      })
  }

  selectedTabChange(changeEvent: MatTabChangeEvent) {
    this.selectedTabIndex = changeEvent.index;
  } 

}
