import { Topic } from "./topic";

export class Forum {
    id: number = 0;
    name: string = '';
    description: string = '';
    sponsor: string = '';
    topics: Topic[]= [];
    moderators: []= [];
}
