import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { Sponsor } from '../model/sponsor';

@Injectable({
  providedIn: 'root'
})
export class SponsorService {

  constructor(private http: HttpClient) { }

  private url: string = environment.url;
  private API_SPONSORS: string = `${this.url}/api/v2/sponsors`;

  getToken(): string {
    return localStorage.getItem('token')!;
  }

  getOption() {
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.getToken()});
    return { headers: httpHeaders };
  }

  getSponsors(): Observable<any> {
    return this.http.get<Sponsor[]>(this.API_SPONSORS, this.getOption())
  }
}
