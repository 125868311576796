export class Organization {
  id: number = 0;
  name: string = '';
  subscriptionContact: string = '';
  contactEmail: string = '';
  startDate: string = '';
  expiryDate: string = '';
  subscriptionAmount: number = 0;
  subscriptionType: string = '';
}
