import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationList } from 'src/model/notification';
import { NotificationService } from 'src/services/notification.service';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {
  substitutorsList: string[] = [];
  content: string = "<br> dasdasdasdas";
  form!: FormGroup;
  formState: string = '';


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<NotificationDialogComponent>,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService) {
      

      this.substitutorsList = data.data.availableSubstitutors?.split(' ').filter((item: string) => item);

      this.formState = data.formState;
      this.createForm(data.data);

  }

  ngOnInit() {
  }

  createForm(form: any) {

    return this.form = this.formBuilder.group({
      message: [form?.message || '', [Validators.required]],
      name: [form?.name || '', [Validators.required]]
    });
  }

  save(){    
    this.updateNotification();
  }

  updateNotification() {
    this.spinner.show();
    this.notificationService.updateNotification(this.form.getRawValue())
      .subscribe({
        next: (result: any) => { },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); this.openSnackBar("Successfully updated.", 'Close');  this.dialogRef.close(true); }

      })
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 3000 });
  }
}
