import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UpdateUser, User } from 'src/model/User';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  private url: string = environment.url;
  private API_ADMIN_USER: string = `${this.url}/api/v2/admin/user`;
  private API_USERS: string = `${this.url}/api/v2/users`;
  private API_USER: string = `${this.url}/api/v2/user`;

  getToken(): string {
    return localStorage.getItem('token')!;
  }

  getOption() {
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.getToken()});
    return { headers: httpHeaders };
  }
  
  saveUser(data:User): Observable<User> {
    const body = JSON.stringify(data);
    return this.http.post<any>(this.API_ADMIN_USER, body, this.getOption());     
  }

  updateAllowloginUser(data:UpdateUser): Observable<UpdateUser> {
    const ALLOW_LOGING_URL = this.API_USER + '/' + data.id;
    const body = JSON.stringify(data);
    return this.http.patch<any>(ALLOW_LOGING_URL, body, this.getOption());
  }

  updateUser(data:UpdateUser): Observable<UpdateUser> {
    const body = JSON.stringify(data);
    return this.http.patch<any>(this.API_ADMIN_USER, body, this.getOption());
  }

  deleteUser(id: number): Observable<User> {
    return this.http.delete<User>(this.API_ADMIN_USER + '/' + id,this.getOption());
  }

  getUserList(): Observable<User[]> {
    return this.http.get<User[]>(this.API_USERS, this.getOption())
  }

}  
  