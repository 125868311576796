import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrganizationService } from 'src/services/organization.service';

@Component({
  selector: 'app-organization-dialog',
  templateUrl: './organization-dialog.component.html',
  styleUrls: ['./organization-dialog.component.scss']
})
export class OrganizationDialogComponent implements OnInit {

  form!: FormGroup;
  formState: string = '';

  subscriptionTypeRb: string = '';
  subscriptionTypeRbanswers: string[] = ['HALF_YEAR', 'YEAR', 'PERPETUAL']; 

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private organizationService: OrganizationService,
    public dialogRef: MatDialogRef<OrganizationDialogComponent>,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe) {

      this.formState = data.formState;
      this.createForm(data.data);
  }

  ngOnInit() {
  }

  createForm(form: any) {

    return this.form = this.formBuilder.group({
      id:  [form?.id || 0, [Validators.required]],
      name: [form?.name || '', [Validators.required]],
      subscriptionContact: [form?.subscriptionContact || '', [Validators.required]],
      subscriptionAmount: [form?.subscriptionAmount || '', [Validators.required]],
      subscriptionType : [form?.subscriptionType || this.subscriptionTypeRbanswers[0], [Validators.required]],
      startDate : [form?.startDate || '', [Validators.required]],
      expiryDate: [form?.expiryDate || '', [Validators.required]],
      contactEmail: [form?.contactEmail || '', [Validators.required]]
    });
  }

  save(){
    if(this.form.valid) {
      this.emailToLowercase();
      if(this.formState == 'NEW'){
        this.addOrganization();
      } else {
        this.updateOrganization();
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  addOrganization() {
    this.spinner.show();
    this.organizationService.saveOrganization(this.form.getRawValue())
      .subscribe({
        next: (result: any) => { },
        error: (error) => { 
          console.log(error); 
          let errorMsg = error.error.error != undefined ? error.error.error + '.' : 'Server error.'
          if(error  && error.error 
                    && error.error.violations && error.error.violations.length>0) {
            errorMsg = error.error.violations[0].message;
          }
          this.openSnackBar(errorMsg, 'Close'); 
          this.spinner.hide(); 
        },
        complete: () => { 
          this.spinner.hide(); 
          this.openSnackBar("Successfully added.", 'Close'); 
          this.dialogRef.close(true); 
        }
      })
  }

  updateOrganization() {
    const formattedDateStart = this.datePipe.transform(this.form.get('startDate')?.value, 'yyyy-MM-dd');
    this.form.patchValue({'startDate': formattedDateStart});

    const formattedDateEnd = this.datePipe.transform(this.form.get('expiryDate')?.value, 'yyyy-MM-dd');
    this.form.patchValue({'expiryDate': formattedDateEnd});

    this.spinner.show();
    this.organizationService.updateOrganization(this.form.getRawValue())
      .subscribe({
        next: (result: any) => { },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); this.openSnackBar("Successfully updated.", 'Close');  this.dialogRef.close(true); }
      })
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 3000 });
  }

  emailToLowercase() {
    const str:String = this.form.get('contactEmail')?.value;
    if(str) {
      this.form.get('email')?.setValue(str.toLowerCase());
    }
  }
}

