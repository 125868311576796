import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationList, Notification } from 'src/model/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }
  
  private url: string = environment.url;
  private API_NOTIFICATION_TEMPLATES: string = `${this.url}/api/v2/notification/templates`;
  private API_NOTIFICATION_TEMPLATE: string = `${this.url}/api/v2/notification/template`;

  getToken(): string {
    return localStorage.getItem('token')!;
  }

  getOption() {
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.getToken()});
    return { headers: httpHeaders };
  }

  updateNotification(data: Notification): Observable<Notification> {
    const body = JSON.stringify(data);
    return this.http.patch<any>(this.API_NOTIFICATION_TEMPLATE, body, this.getOption());
  }

  getNotificationList(): Observable<NotificationList[]> {
    return this.http.get<NotificationList[]>(this.API_NOTIFICATION_TEMPLATES, this.getOption())
  }
  
}  
