import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FromNode } from 'src/model/fromNode';
import { FullTree } from 'src/model/fullTree';
import { Topic } from 'src/model/topic';
import { TopicUpdate } from 'src/model/topicUpdate';
import { TopLevel } from 'src/model/topLevel';

@Injectable({
  providedIn: 'root'
})
export class TopicService {

  constructor(private http: HttpClient) { }

  private url: string = environment.url;
  private API_TOPIC: string = `${this.url}/api/v2/topic`;
  private API_TOPICS_CLASSIFICATION: string = `${this.url}/api/v2/topics/classification/`;
  private API_TOPICS_FORUM: string = `${this.url}/api/v2/topics/forum/`;

  getToken(): string {
    return localStorage.getItem('token')!;
  }

  getOption() {
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.getToken()});
    return { headers: httpHeaders };
  }

  saveTopic(data: Topic): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.post<any>(this.API_TOPIC, body, this.getOption());
  }

  updateTopic(data: TopicUpdate): Observable<any> {
    const API_TOPIC_PATCH_URL = this.API_TOPIC + "/" + data.id;
    const body = JSON.stringify(data);
    return this.http.patch<any>(API_TOPIC_PATCH_URL, body, this.getOption());
  }

  deleteTopic(id: number): Observable<any> {
    const API_TOPIC_PATCH_URL = this.API_TOPIC + "/" + id;
    return this.http.delete<any>(API_TOPIC_PATCH_URL, this.getOption());
  }

  getFullTree(classificationId: number): Observable<FullTree[]> {
    const API_TOPICS = this.API_TOPICS_CLASSIFICATION + classificationId;
    return this.http.get<FullTree[]>(API_TOPICS, this.getOption());
  }

  getForumTopics(forumId: number): Observable<FromNode[]> {
    const API_TOPICS = this.API_TOPICS_FORUM + forumId;
    return this.http.get<FromNode[]>(API_TOPICS, this.getOption())
  }

}