import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionList, SessionNew } from 'src/model/sessionNew';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private http: HttpClient) { }

  private url: string = environment.url;
  private API_SESSION_SEARCH: string = `${this.url}/api/v2/admin/sessions?searchText=`;

  getSessionList(value: string): Observable<SessionList[]> {
    return this.http.get<SessionList[]>(this.API_SESSION_SEARCH.concat(value), this.getOption());
  }

  getToken(): string {
    return localStorage.getItem('token')!;
  }

  getOption() {
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.getToken()});
    return { headers: httpHeaders };
  }

}
