<div class="wraper">
  <div class="header-line">
    <h2 *ngIf="formState=='NEW'">Add forum</h2>
    <h2 *ngIf="formState=='EDIT'">Edit forum</h2>
  </div>
  <form [formGroup]="form" #formForm="ngForm">
    <div>
      <section>
        <div class="inline-grid">
          <mat-label class="dialog-label">Name</mat-label>
          <mat-form-field appearance="outline" class="mr">
            <input matInput placeholder="Name" [formControlName]="'name'" #name>
          </mat-form-field>
        </div>
        <div class="inline-grid">
          <mat-label class="dialog-label">Description</mat-label>
          <mat-form-field appearance="outline" class="mr">
            <input matInput placeholder="Description" [formControlName]="'description'" #description>
          </mat-form-field>
        </div>
        <div class="inline-grid">
          <mat-label class="dialog-label">Sponsorship</mat-label>
          <mat-form-field class="example-full-width" appearance="outline" class="mr">
            <input type="text" placeholder="Pick one" aria-label="Choose Sponsorship" matInput [(ngModel)]="sponsorName"
              [formControlName]="'sponsor'" [matAutocomplete]="autoComplete">
            <mat-autocomplete autoActiveFirstOption #autoComplete="matAutocomplete"
              [displayWith]="displayFnSponsor.bind(this)" (optionSelected)="onSponsorChange($event.option.value)">
              <mat-option *ngFor="let sponsor of filteredSponsors | async" [value]="sponsor">
                <span style="font-weight: 500;">{{sponsor.name}} </span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </section>

      <section>
        <div class="inline-grid">
          <mat-label class="dialog-label">Moderators</mat-label>
          <mat-form-field appearance="outline" class="mr">
            <mat-select [formControlName]="'moderators'" #moderators multiple>
              <mat-select-trigger>
                {{getNameOfFirstModerator() || ''}}
                <span *ngIf="(moderators.value?.length || 0) > 1" class="example-additional-selection">
                  (+{{(moderators.value?.length || 0) - 1}} {{moderators.value?.length === 2 ? 'other' : 'others'}})
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let moderator of moderatorsList" [value]="moderator.id">{{moderator.firstName}}
                {{moderator.lastName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="inline-grid" *ngIf="formState=='NEW'">
          <mat-label class="dialog-label">Topics</mat-label>
          <mat-form-field appearance="outline" class="mr">
            <mat-select [formControlName]="'topics'" #topics multiple>
              <mat-select-trigger>
                {{getNameOfFirstTopic() || ''}}
                <span *ngIf="(topics.value?.length || 0) > 1" class="example-additional-selection">
                  (+{{(topics.value?.length || 0) - 1}} {{topics.value?.length === 2 ? 'other' : 'others'}})
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let topic of topicList" [value]="topic.id">{{topic.name}}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- <div class="inline-grid">
          <mat-label class="dialog-label">Choose topic</mat-label>
          <mat-form-field class="example-full-width" appearance="outline" class="mr">
            <input type="text" placeholder="Pick one" aria-label="Choose topic" matInput 
              [formControlName]="'topics'" #topics [matAutocomplete]="autoComplete">
            <mat-autocomplete autoActiveFirstOption #autoComplete="matAutocomplete"
              [displayWith]="displayFnTopic.bind(this)">

              <mat-select-trigger>
                {{topics.value?.[0] || ''}}
                <span *ngIf="(topics.value?.length || 0) > 1" class="example-additional-selection">
                  (+{{(topics.value?.length || 0) - 1}} {{topics.value?.length === 2 ? 'other' : 'others'}})
                </span>
              </mat-select-trigger>

              <mat-option *ngFor="let topic of filteredTopics | async" [value]="topic">
                <span style="font-weight: 500;">{{topic.name}} </span> <span *ngIf="topic.path != ''"
                  style="font-size: 12px;"> ({{topic.path}})</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div> -->

        <!-- <div class="inline-grid">
          <mat-label class="dialog-label">topics</mat-label>
          <mat-form-field appearance="outline" class="mr">
            <mat-select [formControlName]="'topics'" #topics multiple>
              <mat-select-trigger>
                {{topics.value?.[0] || ''}}
                <span *ngIf="(topics.value?.length || 0) > 1" class="example-additional-selection">
                  (+{{(topics.value?.length || 0) - 1}} {{topics.value?.length === 2 ? 'other' : 'others'}})
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let topic of topicsList" [value]="topic.firstName">{{topic.firstName}}
                {{topic.lastName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
      </section>

    </div>
    <div class="save-cancel">
      <button mat-button (click)="onCancel()" class="mr-btn">Cancel</button>
      <button mat-raised-button type="button" (click)="save()">Save</button>
    </div>
  </form>
</div>