import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Classification } from 'src/model/classification';
import { ClassificationService } from 'src/services/classification.service';

@Component({
  selector: 'app-classification-dialog',
  templateUrl: './classification-dialog.component.html',
  styleUrls: ['./classification-dialog.component.scss']
})
export class ClassificationDialogComponent implements OnInit {
  classificationName: string = "";
  classificationCb: string = "";
  form!: FormGroup;
  formState: string = '';


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private classificationService: ClassificationService,
    public dialogRef: MatDialogRef<ClassificationDialogComponent>,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService) {

      this.formState = data.formState;
      this.createForm(data.data);

  }

  ngOnInit() {
  }

  createForm(form: any) {

    return this.form = this.formBuilder.group({
      id:  [form?.id || 0, [Validators.required]],
      name: [form?.name || '', [Validators.required]]
    });
  }

  save(){
    if(this.formState == 'NEW'){
      this.addClassification();
    } else {
      this.updateClassification();
    }
  }

  addClassification() {
    this.spinner.show();
    this.classificationService.saveClassification(this.form.value.name.trim())
      .subscribe({
        next: (result: any) => { },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); this.openSnackBar("Successfully added.", 'Close'); this.dialogRef.close(true); }

      })
  }

  updateClassification() {
    this.spinner.show();
    this.classificationService.updateClassification(this.form.value.id, this.form.value.name.trim())
      .subscribe({
        next: (result: any) => { },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); this.openSnackBar("Successfully updated.", 'Close');  this.dialogRef.close(true); }

      })
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 3000 });
  }
}
