<div class="wraper">
  <div class="header-line">
  <h2 *ngIf="formState=='NEW'">Add clasification </h2>
  <h2 *ngIf="formState=='EDIT'">Edit clasification </h2>
  </div>
  <form [formGroup]="form" #formForm="ngForm">
    <div class="inline-grid">
      <mat-label class="dialog-label">Name</mat-label>
      <mat-form-field appearance="outline">
        <input matInput placeholder="Name" [formControlName]="'name'" #name>
      </mat-form-field>
    </div>
    <div class="save-cancel">
      <button mat-button (click)="onCancel()" class="mr-btn">Cancel</button>
      <button mat-raised-button type="button" (click)="save()">Save</button>
    </div>
  </form>
</div>