import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { ClassificationDialogComponent } from './main/classification-dialog/classification-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ForumDialogComponent } from './main/forum-dialog/forum-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { DeleteDialogComponent } from './main/delete-dialog/delete-dialog.component';
import { TopicDialogComponent } from './main/topic-dialog/topic-dialog.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { UserDialogComponent } from './main/user-dialog/user-dialog.component';
import { OrganizationDialogComponent } from './main/organization-dialog/organization-dialog.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotificationDialogComponent } from './main/notification-dialog/notification-dialog.component';
import { QuillModule } from 'ngx-quill'
import {MatPaginatorModule} from '@angular/material/paginator';
import { ReportService } from 'src/services/report.service';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AuthInterceptor } from 'src/auth.interceptor';
import { ForumTopicDialogComponent } from './main/forum-topic-dialog/forum-topic-dialog.component';
import {MatCardModule} from '@angular/material/card';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [			
    AppComponent,
    LoginComponent,
    MainComponent,
    ClassificationDialogComponent,
    ForumDialogComponent,
    ForumTopicDialogComponent,
    DeleteDialogComponent,
    TopicDialogComponent,
    UserDialogComponent,
    OrganizationDialogComponent,
    NotificationDialogComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTableModule,
    MatDialogModule,
    MatCheckboxModule,
    MatTreeModule,
    MatIconModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatProgressBarModule,MatCardModule,
    NgxSpinnerModule.forRoot({ type: 'ball-clip-rotate' }),
    QuillModule.forRoot()

    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, DatePipe, { provide: JWT_OPTIONS, useValue: JWT_OPTIONS }, JwtHelperService,{provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}}, MatDatepickerModule, ReportService, 
      {
        provide: LocationStrategy,
        useClass: HashLocationStrategy
      }
     ],
  bootstrap: [AppComponent]


})
export class AppModule { }
