<div class="wraper">
  <div class="header-line">
    <h2 *ngIf="formState=='NEW'">Add organization</h2>
    <h2 *ngIf="formState=='EDIT'">Edit organization</h2>
  </div>

  <form [formGroup]="form" #formForm="ngForm">
    <section>
      <div class="inline-grid">
        <mat-label class="dialog-label">Name</mat-label>
        <mat-form-field appearance="outline" class="mr">
          <input matInput placeholder="Name" [formControlName]="'name'" #name>
        </mat-form-field>
      </div>
      <div class="inline-grid">
        <mat-label class="dialog-label">Subscription contact</mat-label>
        <mat-form-field appearance="outline" class="mr">
          <input matInput placeholder="Subscription contact" [formControlName]="'subscriptionContact'"
            #subscriptionContact>
        </mat-form-field>
      </div>
      <div class="inline-grid">
        <mat-label class="dialog-label">Contact email</mat-label>
        <mat-form-field appearance="outline" class="mr">
          <input matInput placeholder="Contact email" [formControlName]="'contactEmail'" #contactEmail 
          (blur)="emailToLowercase()">
        </mat-form-field>
      </div>
    </section>

    <section>
      <div class="inline-grid">
        <mat-label class="dialog-label">Subscription amount</mat-label>
        <mat-form-field appearance="outline" class="mr">
          <input matInput type="number" min="0" placeholder="Subscription amount" [formControlName]="'subscriptionAmount'"
            #subscriptionAmount>
        </mat-form-field>
      </div>
      <div class="inline-grid">
        <mat-label class="dialog-label">Subscription type</mat-label>
        <mat-form-field appearance="outline" class="mr">
          <mat-select placeholder="Subscription type" [formControlName]="'subscriptionType'" #subscriptionType>
            <mat-option *ngFor="let option of subscriptionTypeRbanswers" [value]="option">{{option}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </section>

    <section>
      <div class="inline-grid">
        <mat-label class="dialog-label">Start date</mat-label>
        <mat-form-field appearance="outline" class="mr">
          <input matInput [matDatepicker]="startDate" placeholder="Start date" readonly formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="inline-grid">
        <mat-label class="dialog-label">Expiry date</mat-label>
        <mat-form-field appearance="outline" class="mr">
          <input matInput [matDatepicker]="expiryDate" placeholder="Expiry date" readonly formControlName="expiryDate">
          <mat-datepicker-toggle matSuffix [for]="expiryDate"></mat-datepicker-toggle>
          <mat-datepicker #expiryDate disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
      <!-- <label>Subscription type</label>
 <mat-radio-group class="radio-group" [color]="'primary'" [(ngModel)]="subscriptionTypeRb" name="subscriptionTypeRb">
    <mat-radio-button class="radio-button" *ngFor="let subscriptionTypeRbanswer of subscriptionTypeRbanswers"
      [value]="subscriptionTypeRbanswer">
      {{subscriptionTypeRbanswer}}
    </mat-radio-button>
  </mat-radio-group>  -->
    </section>

    <div class="save-cancel">
      <button mat-button (click)="onCancel()" class="mr-btn">Cancel</button>
      <button mat-raised-button type="button" (click)="save()">Save</button>
    </div>
  </form>
</div>