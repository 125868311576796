import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Forum } from 'src/model/forum';
import { ForumUpdate } from 'src/model/forumUpdate';

@Injectable({
  providedIn: 'root'
})

export class ForumService {

  constructor(private http: HttpClient) { }

  private url: string = environment.url;
  private API_FORUM: string = `${this.url}/api/v2/forum`;
  private API_FORUMS: string = `${this.url}/api/v2/admin/forums`;
  private API_MODERATORS:string = `${this.url}/api/v2/users?active=true&moderator=true`;

  getToken(): string {
    return localStorage.getItem('token')!;
  }

  getOption() {
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.getToken()});
    return { headers: httpHeaders };
  }

  saveForum(data: Forum): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.post<any>(this.API_FORUM, body, this.getOption());
  }

  updateForum(data: ForumUpdate): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.patch<any>(this.API_FORUM, body, this.getOption());
  }

  // updateForumTopics(data: ForumUpdate): Observable<any> {
  //   const body = JSON.stringify(data);
  //   return this.http.post<any>(this.API_FORUM, body, this.getOption());
  // }

  deleteForum(id: number): Observable<any> {
    const FORUM_URL = this.API_FORUM+'/'+id;
    return this.http.delete<any>(FORUM_URL, this.getOption())
  }
  
  getForum(id: number): Observable<ForumUpdate[]> {
    const FORUM_URL = this.API_FORUM+'/'+id;
    return this.http.get<ForumUpdate[]>(FORUM_URL, this.getOption())
  }

  getForumByTopicId(topicId: number): Observable<ForumUpdate[]> {
    const FORUM_URL = this.API_FORUMS+'/topic/'+topicId;
    return this.http.get<ForumUpdate[]>(FORUM_URL, this.getOption())
  }

  getAllForumList(): Observable<ForumUpdate[]> {
    return this.http.get<ForumUpdate[]>(this.API_FORUMS, this.getOption())
  }

  getForumModeratorsById(forumId: number) {
    const FORUM_MODERATOR_URL = this.API_FORUM+'/'+forumId+'/moderators';
    return this.http.get<ForumUpdate[]>(FORUM_MODERATOR_URL, this.getOption())
  }

  getForumModerators() {
    return this.http.get<ForumUpdate[]>(this.API_MODERATORS, this.getOption())
  }

  getForumList(name: string): Observable<Forum[]> {
    const FORUM_BY_NAME_URL = this.API_FORUM+'?name='+ name;
    return this.http.get<Forum[]>(FORUM_BY_NAME_URL, this.getOption())
  }


}
