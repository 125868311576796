import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Organization } from 'src/model/organization';
import { OrganizationUpdate } from 'src/model/organizationUpdate';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(private http: HttpClient) { }

  private url: string = environment.url;
  private API_ORGANISATIONS: string = `${this.url}/api/v2/organizations`;
  private API_ORGANISATION: string = `${this.url}/api/v2/organization`;

  getToken(): string {
    return localStorage.getItem('token')!;
  }

  getOption() {
    const httpHeaders = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.getToken()});
    return { headers: httpHeaders };
  }

  getOrganizationList(): Observable<Organization[]> {
    return this.http.get<Organization[]>(this.API_ORGANISATIONS, this.getOption())
  }

  saveOrganization(data: Organization): Observable<Organization> {
    const body = JSON.stringify(data);
    return this.http.post<Organization>(this.API_ORGANISATION, body, this.getOption());
  }

  updateOrganization(data: OrganizationUpdate): Observable<OrganizationUpdate> {
    const API_ORGANISATION_URL = this.API_ORGANISATION+'/'+data.id;
    const body = JSON.stringify(data);
    return this.http.patch<OrganizationUpdate>(API_ORGANISATION_URL, body, this.getOption());
  }

  deleteOrganization(id: number): Observable<Organization> {
    const API_ORGANISATION_URL = this.API_ORGANISATION+'/'+id;
    return this.http.delete<Organization>(API_ORGANISATION_URL,this.getOption());
  }

}