<div class="wraper">
  <div class="header-line">
  <h2 *ngIf="formState=='NEW'">Add notification template</h2>
  <h2 *ngIf="formState=='EDIT'">Edit notification template</h2>
  </div>
  <div style="display: flex; margin-bottom: 12px;">
  <div style="line-height: 2;">Available substitutors:</div>

    <div (click)="copyMessage(sub)" class="substitutors" *ngFor="let sub of substitutorsList">
      {{sub}}
      <mat-icon style="font-size: 14px; margin: 5px -10px -13px 5px;" #tooltip="matTooltip" matTooltip="Copy" matTooltipPosition="above" aria-hidden="false" aria-label="copy" fontIcon="content_copy"></mat-icon>
    </div>
  </div>
  <form [formGroup]="form" #formForm="ngForm">

    <quill-editor  [styles]="{height: '250px'}" [formControlName]="'message'" #message></quill-editor>

    <div class="save-cancel">
      <button mat-button (click)="onCancel()" class="mr-btn">Cancel</button>
      <button mat-raised-button type="button" (click)="save()">Save</button>
    </div>
  </form>
</div>