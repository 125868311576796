export class SessionNew {
    name: string = '';
    description: string = '';
    forumId: number = 0;
    startTime: string = '';
}

export class SessionList {
    id: number = 0;
    name: string = '';
    description: string = '';
    startTime: string = '';
    duration: number = 0;
    enableRecording: boolean = true;
    published: boolean = true;
    started: boolean = true;
    completed: boolean = true;
    summary: string = '';
    forum: [] = [];
    moderators: [] = []; 
}

