<div class="wrapper">
  <div class="header"></div>

  <div class="container">
    <div class="form">
      <div class="log-in">
        <form [formGroup]="loginForm" #loginNgForm="ngForm">
          <div style="display: grid;">
            <label class="color-white opacity-50 left-align">EMAIL ADDRESS</label>
            <mat-form-field appearance="outline">
              <input class="color-white-i" type="email" [formControlName]="'email'" matInput (keyup.enter)="logIn()"
              (blur)="emailToLowercase()"
                placeholder="Email address...">
              <mat-error *ngIf="loginForm.get('email')?.hasError('required')">
                Email is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div style="display: grid;">
            <label class="color-white opacity-50 left-align">PASSWORD</label>
            <mat-form-field appearance="outline">
              <input class="color-white-i" type="password" [formControlName]="'password'" matInput (keyup.enter)="logIn()"
                placeholder="Password...">
              <mat-error *ngIf="loginForm.get('password')?.hasError('required')">
                Password is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div style="width: 100%; text-align: center;">
            <button mat-flat-button color="primary" class="button" (click)="logIn()">LOG IN</button>
          </div>
        </form>
      </div>
    </div>
    <div class="phones"></div>
  </div>
</div>