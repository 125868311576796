import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, Observable, startWith } from 'rxjs';
import { Classification } from '../../../model/classification';
import { Moderator } from '../../../model/moderator';
import { SessionList } from '../../../model/sessionNew';
import { Topic } from '../../../model/topic';
import { ClassificationService } from '../../../services/classification.service';
import { ForumService } from '../../../services/forum.service';
import { SponsorService } from '../../../services/sponsor.service';
import { TopicService } from '../../../services/topic.service';
import { Sponsor } from '../../../model/sponsor';

interface TopicList {
  id: number;
  name: string;
  path: string;
}

@Component({
  selector: 'app-forum-dialog',
  templateUrl: './forum-dialog.component.html',
  styleUrls: ['./forum-dialog.component.scss']
})
export class ForumDialogComponent implements OnInit {

  form!: FormGroup;
  formState: string = '';
  classificationSelect: Classification = {} as Classification;
  selectedTopicId: number = 0;
  moderatorsList: Moderator[] = [];
  topicsList: any[] = [];
  selectedModeratorsList: Moderator[] = [];
  selectedTopicsList: Topic[] = [];
  defaultClassificationId: number = 0;

  topicControl = new FormControl<string | TopicList>('');
  topics: TopicList[] = [];
  filteredTopics!: Observable<TopicList[]>;
  sessionsList: SessionList[] = [];

  topicSelect: TopicList = {
    id: 1,
    name: 'Public',
    path: ''
  };

  topicList: TopicList[] = [];
  topicListFromTree: Topic[] = [];

  sponsorName: string = '';
  sponsors: Sponsor[] = [];
  filteredSponsors!: Observable<Sponsor[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private forumService: ForumService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ForumDialogComponent>,
    private topicService: TopicService,
    private spinner: NgxSpinnerService,
    private classificationService: ClassificationService,
    private sponsorService: SponsorService) {
      this.formState = data.formState;
      this.classificationSelect = data.classification;
      this.selectedTopicId=data.topicId;

      this.createForm(data.data);
     
      if(data.data.id != null || data.data.id != undefined){
        this.getForumModeratorsById(data.data.id);
        this.getForumTopics(data.data.id);
        this.sponsorName = data.data.sponsor;
      }
       
      
  }

  ngOnInit() {
    this.getForumModerators();
    if(this.classificationSelect.id) {
      this.getAllTopicsForForum(this.classificationSelect.id);
    }
    this.getSponsors();
  }

  createForm(form: any) {  
    return this.form = this.formBuilder.group({
      id:  [form?.id || 0, [Validators.required]],
      name: [form?.name || '', [Validators.required]],
      description:  [form?.description || '', [Validators.required]],
      sponsor: [form?.sponsor || new Sponsor(), [Validators.required]],
      moderators:  [form?.moderators || [], [Validators.required]],
      topics: [form?.topics || [], [Validators.required]]
    });
  }

  save(){

    const formSponsor = this.form.controls['sponsor'].value;
    if(!formSponsor.id ) {
      const newFormSponsor:Sponsor = {
        name: this.sponsorName
      }
      this.form.controls['sponsor'].setValue(newFormSponsor);
    }
    if(this.formState == 'NEW'){
      this.addForum();       
    } else {
      this.updateForum();
    }
  }

  addForum() {    
    this.spinner.show();
    this.forumService.saveForum(this.form.getRawValue())
      .subscribe({
        next: (result: any) => { },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); this.openSnackBar("Successfully added.", 'Close'); this.dialogRef.close(true); }

      })
  }

  updateForum() {
    this.spinner.show();
    this.forumService.updateForum(this.form.getRawValue())
      .subscribe({
        next: (result: any) => { },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { 
          this.spinner.hide(); 
          this.openSnackBar("Successfully updated.", 'Close'); 
          this.dialogRef.close(this.selectedTopicId); 
        }

      })
  }

  getForumModerators(){
    this.spinner.show();
    this.forumService.getForumModerators()
    .subscribe({
      next: (result: any) => {
      this.moderatorsList = result;
      },
      error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
      complete: () => { this.spinner.hide();  }

    })
  }

  getForumModeratorsById(id: number){
    this.spinner.show();
    this.forumService.getForumModeratorsById(id)
    .subscribe({
      next: (result: any) => {
      this.selectedModeratorsList = result;
      let moderators = this.selectedModeratorsList.map(a => a.id);
      
      this.form.get("moderators")!.patchValue(moderators);
      
      },
      error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
      complete: () => { this.spinner.hide();  }

    })
  }

  getNameOfFirstModerator(){    
    let moderatorId = this.form.get("moderators")!.getRawValue()[0];
    if(moderatorId){
      let mod = this.moderatorsList.find(x => x.id == moderatorId);      
      return mod?.firstName + ' ' + mod?.lastName;
    }
    return null;
  }

  getNameOfFirstTopic(){    
    let topicId = this.form.get("topics")!.getRawValue()[0];
    
    if(topicId){
      let top = this.topicList.find(x => x.id == topicId);      
      
      return top?.name;
    }
    return null;
  }



  onCancel(): void {
    this.dialogRef.close(false);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, { duration: 3000 });
  }

  displayFnTopic(topic: TopicList): string {
    return topic != null ? topic.name : '';
  }

  displayFnSponsor(sponsor: Sponsor): string {
    if(sponsor) {
      return sponsor.name?sponsor.name:'';
    } else {
      return '';
    }
  }


  private _filterTopic(name: string): TopicList[] {
    let filterValue = name.toLowerCase();

    return this.topicList.filter(topic => topic.name.toLowerCase().includes(filterValue));
  }

  getAllClassifications() {
    this.classificationService.getClassificationList()
      .subscribe({
        next: (result: any) => {
          this.defaultClassificationId = result?.find((x: Classification) => x.name == 'Default').id; //activeClassifications;
        },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.getAllTopicsForForum(this.defaultClassificationId) }

      })
  }


  getAllTopicsForForum(id: number) {
    this.spinner.show();
    this.topicService.getFullTree(id)
      .subscribe({
        next: (result: any) => {
          this.topicListFromTree = this.convertTreeToList(result);
          this.topicList = this.createPath(this.topicListFromTree);
        },
        error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
        complete: () => { this.spinner.hide(); }

      })
  }

  getForumTopics(forumId: number){
    this.spinner.show();
    this.topicService.getForumTopics(forumId)
    .subscribe({
      next: (result: any) => {
        this.selectedTopicsList = result;     
        let topics = this.selectedTopicsList.map(a => a.id);
        this.form.get("topics")!.patchValue(topics);
      },
      error: (error) => { console.log(error); this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); this.spinner.hide(); },
      complete: () => { this.spinner.hide();  }

    })
  }

  convertTreeToList(root: any) {
    let stack = [];
    let array: any[] = [];
    let hashMap = {};
    stack = root;

    while (stack.length !== 0) {
      var node = stack.pop();

      if (node.subtopics.length == 0) {
        array.push(node)
      } else {
        array.push(node)
        for (var i = node.subtopics.length - 1; i >= 0; i--) {
          stack.push(node.subtopics[i]);
        }
      }
    }
    return array;
  }

  createPath(topicList: Topic[]) {
    let array: TopicList[] = []

    topicList.forEach(element => {
      let level = element.level;
      let parentId = element.parentId;
      let name = element.name;
      let id = element.id;
      let path = element.name;

      for (let i = level; i > 0; i--) {
        let parentTopic = topicList.find(x => x.id == parentId);
        if (parentTopic) {
          parentId = parentTopic.parentId;
          path = parentTopic.name + '/' + path;
        }
      }

      if (path == name)
        path = '';

      let newTopic: TopicList = {
        id: id,
        name: name,
        path: path
      }

      array.push(newTopic);
    });

    return array;

  }

  onSponsorChange(selectedSponsor: any){ }

  getSponsors() {
    this.spinner.show();
    this.sponsorService.getSponsors()
      .subscribe({
        next: (result: any) => {
          
          this.sponsors = result;

          this.filteredSponsors = this.topicControl.valueChanges.pipe(
            startWith(''),
            map(value => {
              const name = typeof value === 'string' ? value : value?.name;
              return name ? this._filterSponsor(name as string) : this.sponsors.slice();
          }));

        },
        error: (error) => { 
          console.log(error); 
          this.openSnackBar((error && error.error.error != undefined ? error.error.error + '.' : 'Server error.'), 'Close'); 
          this.spinner.hide(); 
        },
        complete: () => { this.spinner.hide(); }

      })
  }

  private _filterSponsor(name: string): Sponsor[] {
    let filterValue = name.toLowerCase();
    return this.sponsors.filter(sponsor => sponsor?.name?.toLowerCase().includes(filterValue));
  }

}
