<div class="wraper">
  <div class="header-line">
  <h2 *ngIf="formState=='NEW'">Add topic</h2>
  <h2 *ngIf="formState=='EDIT'">Edit topic</h2>
  </div>
  <form [formGroup]="form" #formForm="ngForm">
      <div class="inline-grid">
        <mat-label class="dialog-label">Name</mat-label>
      <mat-form-field appearance="outline" class="mr">
        <input matInput placeholder="Name" [formControlName]="'name'" #name>
      </mat-form-field>
    </div>
    <div class="inline-grid" *ngIf="data.data.level == 0 && formState == 'NEW'">
      <mat-label class="dialog-label">Choose classification</mat-label>
      <mat-form-field class="example-full-width" appearance="outline" class="mr">
        <input type="text"
               placeholder="Pick one"
               aria-label="Choose classification"
               matInput
               [formControlName]="'classificationId'"
               [formControl]="classificationControl"
               [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
          <mat-option *ngFor="let classification of filteredClassifications | async" [value]="classification">
            {{classification.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="save-cancel">
      <button mat-button (click)="onCancel()" class="mr-btn">Cancel</button>
      <button mat-raised-button type="button" (click)="save()">Save</button>
    </div>
  </form>
</div>