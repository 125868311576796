export class User {
    email: string = '';
    firstName: string = '';
    lastName: string = '';
    organizationId: number = 0;
    moderator: boolean = false;
    admin: boolean = false;
    publicUser: boolean = false;
    guestSpeaker: boolean = false;
    allowLogin: boolean = false;
}

export class UpdateUser {
    id: number = 0;
    firstName: string = '';
    lastName: string = '';
    organizationId: number = 0;
    moderator: boolean = false;
    admin: boolean = false;
    publicUser: boolean = false;
    guestSpeaker: boolean = false;
    allowLogin: boolean = false;
}