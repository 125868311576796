import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

constructor(private http: HttpClient) { }

private url:string = environment.url;

logIn(username: string, password: string){
  const headers = new HttpHeaders().set('Content-Type', 'application/json');

  return this.http.post<any>(this.url + '/api/v2/auth/login', 
  {
    "username": username,
    "password": password,
    "environment": "ADMIN"
  }
  , { headers })
}

getToken(): string {
  return localStorage.getItem('token')!;
}

refreshToken(refreshToken: string){

  const headers = new HttpHeaders().set('Content-Type', 'application/json');

  return this.http.post<any>(this.url + '/api/v2/auth/refresh_token', 
  {
    "refreshToken": refreshToken
  }
  , { headers })
}

}
